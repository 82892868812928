import React from "react";
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ButtonSecondary from "ui/components/buttons/ButtonSecondary";
import InfoByType from "./InfoByType";


const ModalInfoKeyword = ({ isShow = false, keyword = {}, onClose }: any) => {
    const { t, i18n } = useTranslation();

    const onHide = () => {
        onClose && onClose();
    };

    const keyword_info: any = { ...keyword?.keyword_info, thumbnail: keyword?.image_url || '' };
    const keyword_type = keyword?.keyword_type;

    return (
        <>
            <Modal
                id="modal-info-keyword"
                className="fade bd-example-modal-lg"
                show={!!isShow}
                centered={true}
                scrollable={false}
                onHide={onHide}
            >
                <Modal.Header className="border-0 pt-4">
                    <Modal.Title>{t('Keyword Description')}</Modal.Title>
                    <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onHide}>
                        <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Modal.Header>
                <Modal.Body className="pt-1 pb-0" style={{ minHeight: "450px" }}>
                    <InfoByType info={keyword_info} source_type={keyword_type} />
                </Modal.Body>
                <Modal.Footer className="text-center border-0 pb-4 pt-0">
                    <ButtonSecondary
                        onClick={onHide}
                        variant="contained"
                        text={t('Close')}
                        className="m-0 fs-16"
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default React.memo(ModalInfoKeyword);