import React from "react";
import Sort from "../Sort";
import { useTranslation } from "react-i18next";
import { Button, Collapse } from "react-bootstrap";
import { CATEGORY_TYPE, CATEGORY_GROUP_TYPE, FREQUENCY_TYPE } from "helpers/constans";
import Tooltip from '@mui/material/Tooltip';

const Header = (
    {
        isCollapse = true,
        columnTableRanking = [],
        query = { sort_by: '', order_by: 'DESC' },
        categoryType = String(CATEGORY_TYPE.TREND_INDEX),
        categoryGroupType = String(CATEGORY_GROUP_TYPE.PEOPLE),
        frequencyType = String(FREQUENCY_TYPE.DAILY),
        intervalTimeSearch,
        typeShowParticipation = false,
        handleChangeSorting = undefined,
        handleSetIsCollapse = undefined,
        style = {},
        widthColumn,
    }: any,
    ref: any
) => {
    const { t, i18n } = useTranslation();
    const path_full = window.location.pathname;
    const handleSort = (sort: any) => {
        handleChangeSorting && handleChangeSorting(sort);
    };

    const textRankChange = {
        [String(FREQUENCY_TYPE.DAILY)]: t('Index Chart (last 30 days)'),
        [String(FREQUENCY_TYPE.WEEKLY)]: t('Index Chart (last 12 weeks)'),
        [String(FREQUENCY_TYPE.MONTHLY)]: t('Index Chart (last 6 months)'),
    };

    const renderTableHeader = (key: any, isFixed: boolean = false, index: any) => {
        switch (key) {
            case 'rank':
                return (
                    <th
                        translate="yes"
                        key={index}
                        className="text-center cursor-pointer align-middle"
                        ref={(el) => { if (ref?.current) { ref.current[0] = el; } }}
                        {...(widthColumn ? { width: Number(widthColumn[0]?.attributes?.width?.value) } : { width: '50' })}
                    >
                        <div>{t('Rank')}</div>
                    </th>
                );
            case 'keyword':
                return (
                    <th
                        translate="yes"
                        key={index}
                        className="text-center cursor-pointer align-middle"
                        ref={(el) => { if (ref?.current) { ref.current[1] = el; } }}
                        {...(widthColumn ? { width: Number(widthColumn[1]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '210' : '210' })}
                    >
                        {t('Keyword')}
                    </th>
                );
            case 'user_participation':
                return (
                    (!!typeShowParticipation && (String(frequencyType) === String(FREQUENCY_TYPE.DAILY))) && (intervalTimeSearch === 'D_2') && (
                        <th
                            translate="yes"
                            key={index}
                            className="text-center pe-4 cursor-pointer align-middle"
                            ref={(el) => { if (ref?.current) { ref.current[11] = el; } }}
                            {...(widthColumn ? { width: Number(widthColumn[11]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '130' : '100' })}
                        >
                            <div className="">{t('User Participation')}</div>
                        </th>
                    )
                );
            case 'category':
                return (
                    (!String(path_full).includes('corporation')) &&
                    (<Collapse in={isCollapse || String(path_full).includes('people')}>
                        <th
                            translate="yes"
                            key={index}
                            className="text-center cursor-pointer align-middle"
                            ref={(el) => { if (ref?.current) { ref.current[2] = el; } }}
                            {...(widthColumn ? { width: Number(widthColumn[2]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '140' : '120' })}
                        >
                            {t('Category')}
                        </th>
                    </Collapse>)
                );
            case 'subcategory':
                return (
                    (!String(path_full).includes('corporation')) &&
                    <Collapse in={isCollapse || String(path_full).includes('people')}>
                        <th
                            translate="yes"
                            key={index}
                            className="text-center cursor-pointer align-middle"
                            ref={(el) => { if (ref?.current) { ref.current[3] = el; } }}
                            {...(widthColumn ? { width: Number(widthColumn[3]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '140' : '120' })}
                        >
                            {t('Subcategory')}
                        </th>
                    </Collapse>
                );
            case 'index_chart':
                return (
                    <th
                        translate="yes"
                        key={index}
                        className="text-center cursor-pointer align-middle"
                        ref={(el) => { if (ref?.current) { ref.current[4] = el; } }}
                        {...(widthColumn ? { width: Number(widthColumn[4]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '200' : '180' })}
                    >
                        {textRankChange[String(query?.frequency_type)] || ''}
                    </th>
                );
            case 'popular_index':
                return (
                    <Collapse in={isCollapse || isFixed}>
                        <th
                            translate="yes"
                            key={index}
                            className="text-end cursor-pointer align-middle"
                            onClick={() => handleSort({ sort_by: 'popular_index', order_by: (query?.sort_by === 'popular_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                            ref={(el) => { if (ref?.current) { ref.current[12] = el; } }}
                            {...(widthColumn ? { width: Number(widthColumn[12]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '140' : '100' })}
                        >
                            {t('Popular Index')}
                            <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="popular_index" />
                        </th>
                    </Collapse>
                );
            case 'trend_index':
                return (
                    <Collapse in={isCollapse || isFixed}>
                        <th
                            translate="yes"
                            key={index}
                            className="text-end cursor-pointer align-middle"
                            onClick={() => handleSort({ sort_by: 'index', order_by: (query?.sort_by === 'index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                            ref={(el) => { if (ref?.current) { ref.current[5] = el; } }}
                            {...(widthColumn ? { width: Number(widthColumn[5]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '120' : '100' })}
                        >
                            {t('Issue Index')}
                            <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="index" />
                        </th>
                    </Collapse>
                );
            case 'stock_index':
                return (
                    (String(categoryType) === String(CATEGORY_TYPE.STOCK)) && (
                        <Collapse in={isCollapse || isFixed}>
                            <th
                                translate="yes"
                                key={index}
                                className="text-end cursor-pointer align-middle"
                                onClick={() => handleSort({ sort_by: 'stock_index', order_by: (query?.sort_by === 'stock_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                                ref={(el) => { if (ref?.current) { ref.current[6] = el; } }}
                                {...(widthColumn ? { width: Number(widthColumn[6]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '120' : '100' })}
                            >
                                {t('Stock Index')}
                                <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="stock_index" />
                            </th>
                        </Collapse>
                    )
                );
            case 'activity_index':
                return (
                    (String(categoryGroupType) === String(CATEGORY_GROUP_TYPE.PEOPLE)) && (
                        <Collapse in={isCollapse || isFixed}>
                            <th
                                translate="yes"
                                key={index}
                                className="text-end cursor-pointer align-middle"
                                onClick={() => handleSort({ sort_by: 'activity_index', order_by: (query?.sort_by === 'activity_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                                ref={(el) => { if (ref?.current) { ref.current[6] = el; } }}
                                {...(widthColumn ? { width: Number(widthColumn[6]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '120' : '100' })}
                            >
                                {t('Activity Index')}
                                <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="activity_index" />
                            </th>
                        </Collapse>
                    )
                );
            case 'search_index':
                return (
                    <Collapse in={isCollapse || isFixed}>
                        <th
                            translate="yes"
                            key={index}
                            className="text-end cursor-pointer align-middle"
                            onClick={() => handleSort({ sort_by: 'search_index', order_by: (query?.sort_by === 'search_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                            ref={(el) => { if (ref?.current) { ref.current[7] = el; } }}
                            {...(widthColumn ? { width: Number(widthColumn[7]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '120' : '100' })}
                        >
                            {t('Search Index')}
                            <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="search_index" />
                        </th>
                    </Collapse>
                );
            case 'viral_index':
                return (
                    <Collapse in={isCollapse || isFixed}>
                        <th
                            translate="yes"
                            key={index}
                            className="text-end cursor-pointer align-middle"
                            onClick={() => handleSort({ sort_by: 'viral_index', order_by: (query?.sort_by === 'viral_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                            ref={(el) => { if (ref?.current) { ref.current[8] = el; } }}
                            {...(widthColumn ? { width: Number(widthColumn[8]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '120' : '100' })}
                        >
                            {t('Viral Index')}
                            <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="viral_index" />
                        </th>
                    </Collapse>
                );
            case 'news_index':
                return (
                    <Collapse in={isCollapse || isFixed}>
                        <th
                            translate="yes"
                            key={index}
                            className="text-end pe-4 cursor-pointer align-middle"
                            onClick={() => handleSort({ sort_by: 'news_index', order_by: (query?.sort_by === 'news_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                            ref={(el) => { if (ref?.current) { ref.current[9] = el; } }}
                            {...(widthColumn ? { width: Number(widthColumn[9]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '120' : '90' })}
                        >
                            <div>
                                {t('News Index')}
                                <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="news_index" />
                            </div>
                        </th>
                    </Collapse>
                );
            case 'p_index':
                return (
                    !!typeShowParticipation && (
                        <Collapse in={isCollapse || isFixed}>
                            <th
                                translate="yes"
                                key={index}
                                className="text-end pe-4 cursor-pointer align-middle"
                                onClick={() => handleSort({ sort_by: 'p_index', order_by: (query?.sort_by === 'p_index' && query?.order_by === 'DESC') ? 'ASC' : 'DESC' })}
                                ref={(el) => { if (ref?.current) { ref.current[10] = el; } }}
                                {...(widthColumn ? { width: Number(widthColumn[10]?.attributes?.width?.value) } : { width: i18n?.language === 'en' ? '110' : '100' })}
                            >
                                <div>
                                    {t('P Index')}
                                    <Tooltip title={t("The Participation Index reflects user engagement in the index. Use your participation to boost the keywords you're interested in!")} placement="top-start">
                                        <Button variant="light" className="ms-1 p-0 fs-10" style={{ width: '16px', height: '16px', fontSize: '9px' }}>?</Button>
                                    </Tooltip>
                                    <Sort sort_by={query?.sort_by} order_by={query?.order_by} name="p_index" />
                                </div>
                            </th>
                        </Collapse>
                    )
                );
            default:
                return null;
        }
    };

    const onSetIsCollapse = () => {
        handleSetIsCollapse && handleSetIsCollapse();
    };

    return (
        <>
            <thead style={style}>
                <tr>
                    {columnTableRanking.map((key: any, index: any) => renderTableHeader(key?.uuid, key?.isFixed, index))}
                </tr>
            </thead>
        </>
    );
};

export default React.memo(React.forwardRef(Header));
