import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { CATEGORY_GROUP_TYPE, CATEGORY_INDEX_RANK_TYPE, CATEGORY_RANK_TYPE, FREQUENCY_TYPE, PATH_CATEGORY_GROUP_TYPE, PRODUCT_TYPE, SORT_BY_INDEX_RANK_TYPE, TYPE_MESSAGES } from 'helpers/constans';
import { formatNumberWithCommas, getDateRange, getSortColumnTableRanking } from "helpers/format";
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Nav, Spinner, Tab } from 'react-bootstrap';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createSelector } from "reselect";
import { getAllCategoriesNormal, getAllTicketsNormal, getKeywordConfirmLatestNormal, getRankingKeywordsByNormal } from 'store/thunks';
import { myTicket as getOnMyTicket, getSurveyForms, postPaymentProcess, postVote } from "store/voting/thunk";
import ButtonPrimary from "ui/components/buttons/ButtonPrimary";
import ButtonSecondary from "ui/components/buttons/ButtonSecondary";
import RadioPrimary from "ui/components/buttons/RadioPrimary";
import SwitchPrimary from "ui/components/buttons/SwitchPrimary";
import useToastNotifications from "ui/components/Hooks/useToastNotifications";
import RangeDatePicker from "ui/components/pickers/RangeDatePicker";
import BasicTabs from "ui/components/tab/BasicTabs";
import { IMAGES } from "ui/constant/theme";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import DropdownCategory from "./Dropdown/DropdownCategory";
import ModalGoToLogin from "./Modals/ModalGoToLogin";
import ModalInfoKeyword from "./Modals/ModalInfoKeyword";
import ModalKeywordDetail from "./Modals/ModalKeywordDetail";
import ModalNotiVote from "./Modals/ModalNotiVote";
import ModalPurchase from "./Modals/ModalPurchase";
import ModalSubcategory from "./Modals/ModalSubcategory";
import ModalSurvey from "./Modals/ModalSurvey";
import ModalTheme from "./Modals/ModalTheme";
import ModalVoting from "./Modals/ModalVoting";
import TableContainer from "./Table";
import Loading from "./Table/Loading";

const SORT_BY_DEFAULT = 'popular_index';

const ORDER_BY_DEFAULT = 'DESC';
const LIMIT_PER_PAGE = 30;
registerLocale("en", en);
registerLocale("ko", ko);

const createArray = () => {
    const obj = {
        isLoading: true,
    };
    const array = Array.from({ length: 10 }, (_, index) => ({
        ...obj,
        id: index + 1,
        rank: index + 1
    }));
    return array;
};

const DATA_KEYWORD_DEFAULT_LOADING = createArray();

const Category = ({ categoryType }) => {

    const { notify } = useToastNotifications();
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => state?.Login);
    const { myTicket } = useSelector((state) => state?.Voting);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const selectLayoutState = (state) => state.Master;

    const MasterProperties = createSelector(
        selectLayoutState,
        (state) => ({ menuSideBar: state.menuSideBar })
    );
    const { menuSideBar } = useSelector(MasterProperties);

    const [isShowFullColumns, setIsShowFullColumns] = useState(!!localStorage.getItem("IS_SHOW_FULL_COLUMNS") ? localStorage.getItem("IS_SHOW_FULL_COLUMNS") === 'true' : (window.innerWidth < 1300 ? false : true))

    const path_full = window.location.pathname;

    const isFirstLoadingPageRef = useRef(true);

    const paymentCurrentRef = useRef('');

    const INTERVAL_TYPE = [{ label: t('Daily'), value: 1 }, { label: t('Weekly'), value: 2 }, { label: t('Monthly'), value: 3 }];

    const INTERVAL_TIME = {
        [`${INTERVAL_TYPE[0].value}`]: [{ label: t('2 Days Ago'), value: 'D_2' }, { label: t('3 Days Ago'), value: 'D_3' }, { label: t('7 Days Ago'), value: 'D_7' }, { label: t('Select Date'), value: 'SE' }],
        [`${INTERVAL_TYPE[1].value}`]: [{ label: t('Last Week'), value: 'W_1' }, { label: t('2 Weeks Ago'), value: 'W_2' }, { label: t('3 Weeks Ago'), value: 'W_3' }, { label: t('Select Date'), value: 'SE' }],
        [`${INTERVAL_TYPE[2].value}`]: [{ label: t('Last Month'), value: 'M_1' }, { label: t('2 Months Ago'), value: 'M_2' }, { label: t('3 Months Ago'), value: 'M_3' }, { label: t('Select Date'), value: 'SE' }],
    };

    const [searchParams] = useSearchParams();
    const category_id = searchParams.get('category_id');
    const category_level_1 = searchParams.get('category_level_1');
    const category_level_2 = searchParams.get('category_level_2');
    const category_level_3 = searchParams.get('category_level_3');
    const category_level_4 = searchParams.get('category_level_4');
    const sub_category_id = searchParams.get('sub_category_id');
    const level_sub = searchParams.get('level_sub');
    const rank_type = searchParams.get('rank_type');

    const navigationLevel_0 = category_id ? menuSideBar?.find((item) => String(item?.id) === String(category_id)) : null;
    const navigationLevel_1 = category_level_1 && (Number(level_sub) > 1) ? navigationLevel_0?.subs?.find((item) => (String(item?.id) === String(category_level_1) || ((String(item?.category_group_type) === String(CATEGORY_GROUP_TYPE.CORPORATION)) && String(item?.queries?.level_3_category_id) === String(category_level_2)))) : null;
    const navigationLevel_2 = Number(level_sub) > 2 ? navigationLevel_1?.subs?.find((item) => String(item?.id) === String(category_level_2)) : null;

    const GET_SORT_COLUMN_TABLE_RANKING = getSortColumnTableRanking(path_full, rank_type);

    const [query, setQuery] = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, LIMIT_PER_PAGE),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        keyword: withDefault(StringParam, ""),
        sub_category_id: withDefault(StringParam, category_level_4 || category_level_3 || category_level_2 || category_level_1 || ''),
        date: withDefault(StringParam, ''),
        frequency_type: withDefault(StringParam, INTERVAL_TYPE[0]?.value),
        interval_time: withDefault(StringParam, INTERVAL_TIME[`${INTERVAL_TYPE[0]?.value}`][0]?.value),
        category_id: withDefault(StringParam, ''),
        category_level_1: withDefault(StringParam, ''),
        category_level_2: withDefault(StringParam, ''),
        category_level_3: withDefault(StringParam, ''),
        category_level_4: withDefault(StringParam, ''),
        is_clear: withDefault(StringParam, '0'),
        rank_type: withDefault(StringParam, ''),
        is_theme: withDefault(StringParam, ''),
        level_sub: withDefault(StringParam, ''),
    });

    const [isLoadMore, setIsLoadMore] = useState(true);

    const [isShowTheme, setIsShowTheme] = useState(false);

    const [isShowModalVoting, setIsShowModalVoting] = useState(false);

    const [keywordVote, setKeywordVote] = useState(null);
    const [ticketAmountVote, setTicketAmountVote] = useState(0);
    const [keywordVotedInfo, setKeywordVotedInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmLogin, setConfirmLogin] = useState(false);
    const [chargeInfo, setChargeInfo] = useState(null);

    const [confirmDate, setConfirmDate] = useState(null);

    const [surveyForm, setSurveyForm] = useState(null);

    const [tickets, setTickets] = useState([]);

    const [categories, setCategories] = useState([]);

    const [categoriesLevel, setCategoriesLevel] = useState(null);

    const [optionCategoryDropdown, setOptionCategoryDropdown] = useState({});

    const [categoryDropdown, setCategoryDropdown] = useState({});

    const [keywords, setKeywords] = useState({ list: [], total: 0 });

    const [isKeywordLoading, setIsKeywordLoading] = useState(false);

    const [keywordDetail, setKeywordDetail] = useState(null);

    const [keywordInfo, setKeywordInfo] = useState(null);

    const [surveyDetail, setSurveyDetail] = useState(null);

    const [keywordSearch, setKeywordSearch] = useState(query.keyword || '');

    const [dateSearch, setDateSearch] = useState(query?.date || '');

    const [frequencyTypeSearch, setFrequencyTypeSearch] = useState(INTERVAL_TYPE.find((item) => String(item?.value) === String(query?.frequency_type)) || INTERVAL_TYPE[0]);

    const [intervalTimeSearch, setIntervalTimeSearch] = useState(INTERVAL_TIME[`${frequencyTypeSearch?.value}`]?.find((item) => String(item?.value) === String(query?.interval_time))?.value || INTERVAL_TIME[`${frequencyTypeSearch?.value}`][0]?.value);

    const [categorySearch, setCategorySearch] = useState(null);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            keyword: keywordSearch || '',
            date: getDateRange(frequencyTypeSearch?.value, dateSearch)[0],
            frequency_type: frequencyTypeSearch?.value || INTERVAL_TYPE[0]?.value,
            interval_time: intervalTimeSearch,
            time_request: + new Date(),
            is_clear: '0'
        });
    };

    const resetData = (categoryChange = '') => {
        const _change = !!categoryChange ? categoryChange : formatTab(tabs?.find((tab) => String(tab?.value) === String((query?.rank_type || query?.category_level_2 || query?.category_level_1 || query?.category_id))), true);

        const queryNew = {
            ...query,
            sort_by: SORT_BY_INDEX_RANK_TYPE?.[String(_change?.rank_type)] || SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            keyword: '',
            is_theme: '',
            date: moment().subtract(2, "days").format("Y-MM-DD"),
            frequency_type: INTERVAL_TYPE[0]?.value,
            interval_time: INTERVAL_TIME[`${INTERVAL_TYPE[0]?.value}`][0]?.value,
            ...({
                ..._change,
                sub_category_id: _change?.category_level_4 || _change?.category_level_3 || _change?.category_level_2 || _change?.category_level_1 || '',
            }),
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
    };

    const handleQueryData = async () => {
        try {
            if (!!isFirstLoadingPageRef.current && Number(query?.page) !== 1) {
                isFirstLoadingPageRef.current = false;
                setQuery({
                    ...query,
                    page: 1,
                    time_request: + new Date(),
                });
                return;
            };
            isFirstLoadingPageRef.current = false;
            setIsKeywordLoading((prev) => true);
            if (Number(query?.page) === 1) {
                scrollToTop();
                setKeywords((_prev) => ({
                    total: 0,
                    list: DATA_KEYWORD_DEFAULT_LOADING
                }));
            }

            setDateSearch(query?.date || moment().subtract(2, "days").format("Y-MM-DD"));
            setKeywordSearch(query?.keyword || '');
            setFrequencyTypeSearch(INTERVAL_TYPE.find((item) => String(item?.value) === String(query?.frequency_type)) || INTERVAL_TYPE[0]);
            setIntervalTimeSearch(query?.interval_time || INTERVAL_TIME[`${INTERVAL_TYPE[0]?.value}`][0]?.value);

            setCategorySearch((_prev) => (
                (String(_prev?.subs?.sub5?.id) === String(query?.sub_category_id)) ||
                    (String(_prev?.subs?.sub4?.id) === String(query?.sub_category_id)) ||
                    (String(_prev?.subs?.sub3?.id) === String(query?.sub_category_id)) ||
                    (String(_prev?.subs?.sub2?.id) === String(query?.sub_category_id)) ||
                    (String(_prev?.subs?.sub1?.id) === String(query?.sub_category_id)) ? _prev : null
            ));

            const res = await getRankingKeywordsByNormal(query);
            setIsLoadMore((_prev) => res?.data?.list?.length === LIMIT_PER_PAGE);
            setKeywords((prev) => ({
                list: Number(query?.page) === 1 ? (res?.data?.list || []) : (prev?.list || []).concat(res?.data?.list || []),
                total: res?.data?.pagination?.total || 0,
            }));
            setIsKeywordLoading((prev) => false);

        } catch (error) {
            setIsKeywordLoading((prev) => false);
        }
    };

    const handleChangeSorting = (sortBy) => {
        setQuery({ ...query, ...sortBy, page: 1 });
    };

    const handleLoadMore = () => {
        setQuery({
            ...query,
            page: query?.page + 1
        });
    }

    const handleChangePicker = (value, type) => {
        setDateSearch((_prev) => value ? moment(value).format("Y-MM-DD") : '');
    }

    const handleChangeDatePicker = (date) => {
        const frequencyValue = String(frequencyTypeSearch?.value);
        switch (frequencyValue) {
            case String(FREQUENCY_TYPE.WEEKLY):
                handleChangePicker(moment(date).startOf('isoWeek').toDate());
                break;
            case String(FREQUENCY_TYPE.MONTHLY):
            case String(FREQUENCY_TYPE.DAILY):
                handleChangePicker(date);
                break;
            default:
                throw new Error('Invalid frequency type');
        }
    }

    const handleChangeIntervalTime = (value) => {
        setIntervalTimeSearch((_prev) => value);
    }

    const goToLogin = () => {
        const currentUrl = window.location.pathname + window.location.search; // Get the current URL
        localStorage.setItem('referralUrl', currentUrl);
        navigate('/login');
    };

    useEffect(() => {
        const mapping = {
            'D_2': moment().subtract(2, 'days').startOf('day').toDate(),
            'D_3': moment().subtract(3, 'days').toDate(),
            'D_7': moment().subtract(7, 'days').toDate(),
            'W_1': moment().subtract(1, 'weeks').startOf('isoWeek').toDate(),
            'W_2': moment().subtract(2, 'weeks').startOf('isoWeek').toDate(),
            'W_3': moment().subtract(3, 'weeks').startOf('isoWeek').toDate(),
            'M_1': moment().subtract(1, 'months').startOf('month').toDate(),
            'M_2': moment().subtract(2, 'months').startOf('month').toDate(),
            'M_3': moment().subtract(3, 'months').startOf('month').toDate(),
        };

        if (mapping.hasOwnProperty(intervalTimeSearch)) {
            handleChangePicker(mapping[intervalTimeSearch], 'SELECT_OPTION');
        }

    }, [intervalTimeSearch]);

    const handleSetKeywordVote = (row) => {
        if (!user?.user?.id) {
            setConfirmLogin(true);
            return;
        }
        setKeywordVote((_prev) => row);
        setTicketAmountVote((_prev) => 0);
        setIsShowModalVoting((_prev) => true);
    };

    const handleSetKeywordSurvey = (row) => {
        if (!user?.user?.id) {
            setConfirmLogin(true);
            return;
        }
        setSurveyDetail(row);
    };

    const handleGetTicket = async () => {
        try {
            dispatch(getOnMyTicket({}));
        } catch (error) {
            return error;
        }
    };

    const triggerRefresh = async () => {
        try {
            setTicketAmountVote((prev) => 0);
            setIsShowModalVoting((_prev) => false);
            handleGetTicket();
        } catch (error) {
            return error;
        }
    }

    const handleVote = async () => {
        try {
            if (isLoading) {
                return;
            }
            if (Number(ticketAmountVote) > Number(myTicket?.ticket_amount)) {
                notify('error', t('All your votes are waiting to be used. If you would like to vote additionally, please recharge your voting ticket.'));
                return;
            }
            setIsLoading((_prev) => true);
            const data = {
                category_id: Number(keywordVote?.category_id || ''),
                keyword_id: Number(keywordVote?.keyword_id || ''),
                ticket_amount: Number(ticketAmountVote),
            };
            const response = await postVote(data);
            if (response?.code === 200) {
                setIsLoading((_prev) => false);
                setKeywordVotedInfo((_prev) => response?.data);
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                notify('error', `${response}`);
            }
        } catch (error) {
            setIsLoading((_prev) => false);
            notify('error', `${error?.message || ""}`);
            return error;
        }
    }

    const handleCloseVote = () => {
        if (!isLoading) {
            setIsShowModalVoting((_prev) => false);
        }
    }
    const handleSetChargeInfo = (info) => {
        setChargeInfo((_prev) => ({ ..._prev, ...info }));
    }

    const handleSetTicketAmountVote = (ticket) => {
        setTicketAmountVote((_prev) => ticket);
    }

    const handlePollVote = (num) => {
        if (Number(ticketAmountVote) + Number(num) > Number(myTicket?.ticket_amount)) {
            notify('error', t('Your current points are insufficient to perform this action!'));
            return;
        }
        setTicketAmountVote((prev) => {
            if (num === 'all') {
                return myTicket?.ticket_amount;
            }
            return (Number(prev) + Number(num));
        });
    }

    const handlePurchase = async () => {
        try {
            if (isLoading || !chargeInfo?.ticket_id) {
                return;
            }
            setIsLoading((_prev) => true);
            const { protocol, hostname } = window.location;
            const domain = `${protocol}//${hostname}`;
            const data = {
                product_type: PRODUCT_TYPE.TICKET,
                product_id: chargeInfo?.ticket_id,
                useragent: window.innerWidth < 600 ? 'WM' : 'WP', // WP | WM
                return_url: `${domain}/payment/success`,
                cancel_url: `${domain}/payment/error`
            };
            const response = await postPaymentProcess(data);
            setIsLoading((_prev) => false);
            if (response?.code === 200) {
                paymentCurrentRef.current = response?.data?.payment_id;
                const win = window.open('about:blank', '_blank');
                win?.document
                    ?.write(`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
                <html xmlns="http://www.w3.org/1999/xhtml" >
                <head>
                <meta http-equiv="Content-Type" content="text/html; charset=euc-kr">
                <link href="./css/style.css" type="text/css" rel="stylesheet"  media="all" />
                <title>*** �ٳ� �ſ�ī�� ���� ***</title>
                </head>
                <body>
                    <form name="form" ACTION=${response?.data?.start_url} METHOD="POST" >
                    <input TYPE="HIDDEN" NAME="STARTPARAMS" VALUE="${response?.data?.start_params}">
                    </form>
                    <script>
                        document.form.submit();
                    </script>
                </body>`);
            } else {
                setIsLoading((_prev) => false);
                notify('error', `${response}`);
            }
        } catch (error) {
            setIsLoading((_prev) => false);
            notify('error', `${error?.message || ""}`);
            return error;
        }
    }

    const handleCloseChargeInfo = () => {
        if (!isLoading) {
            setChargeInfo((_prev) => null);
        }
    }

    const handleSetKeywordDetail = (detail) => {
        setKeywordDetail((_prev) => detail);
    }

    const handleCloseKeywordDetail = () => {
        setKeywordDetail((_prev) => null);
    }

    const handleCloseSurveyDetail = () => {
        setSurveyDetail((_prev) => null);
    }

    const handleShowMore = (cateArr, level_sub) => {
        setCategoriesLevel({ categories: cateArr, level_sub });
    }

    const handleCloseTheme = () => {
        setIsShowTheme((_prev) => false);
    }

    const searchByTheme = (theme_id) => {
        if (theme_id) {
            setQuery({
                ...query,
                page: 1,
                is_theme: '1',
                category_level_2: theme_id || '',
                sub_category_id: theme_id || '',
                time_request: + new Date(),
            });
        }
        setIsShowTheme((_prev) => false);
    }


    const handleSetKeywordInfo = (info) => {
        setKeywordInfo((_prev) => info);
    }

    const handleCloseKeywordInfo = () => {
        setKeywordInfo((_prev) => null);
    }


    useEffect(() => {
        if (!!query?.category_id && !!query?.date) {
            handleQueryData();
        }
    }, [JSON.stringify(query)]);

    useEffect(() => {
        if (!!query?.category_level_4 || !!query?.category_level_3 || !!query?.category_level_2 || !!query?.category_level_1 || !!query?.category_id) {
            const handleGetSurveyForms = async () => {
                try {
                    const res = await getSurveyForms(query?.category_level_4 || query?.category_level_3 || query?.category_level_2 || query?.category_level_1 || query?.category_id, {});
                    setSurveyForm((prev) => res?.data || null);
                } catch (error) {

                }
            };
            handleGetSurveyForms();
        }
    }, [query?.category_id, query?.category_level_1, query?.category_level_2, query?.category_level_3, query?.category_level_4]);

    useEffect(() => {
        try {
            if (categories?.length === 0 || !query?.category_id) {
                return;
            }
            const cate = query?.category_id ? (categories || [])?.find((item) => String(item?.category_id) === String(query?.category_id)) : null;

            const cate1 = (cate || null)?.subs?.find((item) => String(item?.category_id) === String(query?.category_level_1));
            const cate2 = (cate1 || null)?.subs?.find((item) => String(item?.category_id) === String(query?.category_level_2));
            const cate3 = (cate2 || null)?.subs?.find((item) => String(item?.category_id) === String(query?.category_level_3));
            const cate4 = (cate3 || null)?.subs?.find((item) => String(item?.category_id) === String(query?.category_level_4));

            setOptionCategoryDropdown((_prev) => cate);

            if (cate?.category_id && !query?.category_id) {
                setQuery({ ...query, category_id: cate?.category_id, page: 1 }, "push");
                return;
            }

            if (query?.category_level_4 || query?.category_level_3 || query?.category_level_2 || query?.category_level_1) {
                setCategoryDropdown((_prev) => ({
                    ...(cate1?.name ? { level_1: { label: cate1?.name, value: cate1?.category_id } } : {}),
                    ...(cate2?.name ? { level_2: { label: cate2?.name, value: cate2?.category_id } } : {}),
                    ...(cate3?.name ? { level_3: { label: cate3?.name, value: cate3?.category_id } } : {}),
                    ...(cate4?.name ? { level_4: { label: cate4?.name, value: cate4?.category_id } } : {}),
                }));
            };
            if (!categorySearch && !!query?.sub_category_id) {
                if ((Number(query?.level_sub) === 1 && !!query?.category_level_1) || (Number(query?.level_sub) === 2 && !!query?.category_level_2) || (Number(query?.level_sub) === 3 && !!query?.category_level_3)) {
                    setCategorySearch((_prev) => ({ label: "", value: query?.sub_category_id || '' }));
                }
            }

        } catch (error) {
            return error;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query?.category_id, query?.sub_category_id, query?.category_level_4, query?.category_level_3, query?.category_level_2, query?.category_level_1, JSON.stringify(categories), categoryType]);

    useEffect(() => {
        if (!query?.date && confirmDate) {
            if (String(query?.frequency_type) === String(INTERVAL_TYPE[0]?.value)) {
                setQuery({
                    ...query,
                    interval_time: moment(confirmDate?.daily?.date).isSame(moment().subtract(2, "days"), 'day') ? 'D_2' : 'SE',
                    date: confirmDate?.daily?.date || moment().subtract(2, "days").format("Y-MM-DD")
                }, "push");
            } else if (String(query?.frequency_type) === String(INTERVAL_TYPE[1]?.value)) {
                setQuery({
                    ...query,
                    interval_time: moment(confirmDate?.weekly?.daily?.date).isSame(moment().subtract(1, 'weeks').startOf('isoWeek'), 'day') ? 'W_1' : 'SE',
                    date: confirmDate?.weekly?.daily?.date || moment().subtract(2, "days").format("Y-MM-DD")
                }, "push");
            } else {
                setQuery({
                    ...query,
                    interval_time: 'D_2',
                    date: moment().subtract(2, "days").format("Y-MM-DD")
                }, "push");
            }
        }
    }, [JSON.stringify(confirmDate), query]);

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [res1, res2, res3] = await Promise.all([getAllCategoriesNormal({}), getAllTicketsNormal(), getKeywordConfirmLatestNormal()]);
                setCategories(() => res1?.data || []);
                setTickets(() => res2?.data || []);
                setConfirmDate((_prev) => (res3?.data || null));
            } catch (error) {
                return error;
            }
        };
        handleCallAllOption();
    }, []);

    const typeShowParticipation = useMemo((_prev) => {
        return surveyForm?.type || '';
    }, [JSON.stringify(surveyForm)]);

    useEffect(() => {
        if (frequencyTypeSearch) {
            setFrequencyTypeSearch((prev) => INTERVAL_TYPE.find((item) => item.value === prev?.value) || INTERVAL_TYPE[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        const channel = new BroadcastChannel('my_channel_payment');
        channel.onmessage = (event) => {
            const payment_id = paymentCurrentRef.current;
            if (event.data.type === TYPE_MESSAGES.PAYMENT_SUCCESS) {
                setIsLoading((prev) => false);
                handleGetTicket();
                if (String(payment_id) === String(event.data.payment_id)) {
                    setChargeInfo((_prev) => ({ status: 'SUCCESS' }));
                }
                return;
            }
            if ([TYPE_MESSAGES.PAYMENT_CANCEL, TYPE_MESSAGES.PAYMENT_ERROR]?.includes(event.data.type) && String(payment_id) === String(event.data.payment_id)) {
                setIsLoading((prev) => false);
                setChargeInfo((_prev) => ({ status: 'ERROR' }));
                return;
            }
        };
        return () => {
            channel.close();
        };
    }, []);

    const tabs = useMemo(() => {
        let m = [];
        const subs = menuSideBar?.find((item) => String(path_full)?.includes(item?.path) && item?.path !== '/');

        if (String(path_full).includes('people')) {

            const sub_1 = !!query?.category_level_1 ? subs?.subs?.find((it) => String(it?.id) === String(query?.category_level_1)) : subs;
            const sub_2 = !!query?.category_level_2 ? sub_1?.subs?.find((it) => String(it?.id) === String(query?.category_level_2)) : null;

            m = !!sub_2 ? [
                {
                    ...(sub_2?.subs[0]),
                    kr_name: "인기 지수",
                    name: "Popular Index",
                    title: t("Popular Index"),
                    rank_type: (sub_2?.subs[0])?.rank_type,
                    text: t("Popular Index"),
                    value: (sub_2?.subs[0])?.rank_type,
                },
                {
                    ...(sub_2?.subs[1]),
                    kr_name: "이슈 지수",
                    name: "Issue Index",
                    title: t("Issue Index"),
                    rank_type: (sub_2?.subs[1])?.rank_type,
                    text: t("Issue Index"),
                    value: (sub_2?.subs[1])?.rank_type,
                },
                {
                    id: null,
                    kr_name: "검색 지수",
                    name: "Search Index",
                    title: t("Search Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX,
                    queries: {
                        ...(sub_2?.subs[0])?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX
                    },
                    subs: [],
                    text: t("Search Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX,
                },
                {
                    id: null,
                    kr_name: "뉴스 지수",
                    name: "News Index",
                    title: t("News Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX,
                    queries: {
                        ...(sub_2?.subs[0])?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX,
                    },
                    subs: [],
                    text: t("News Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX,
                },
                {
                    id: null,
                    kr_name: "바이럴 지수",
                    name: "Viral Index",
                    title: t("Viral Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX,
                    queries: {
                        ...(sub_2?.subs[0])?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX,
                    },
                    subs: [],
                    text: t("Viral Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX,
                },
                {
                    id: null,
                    kr_name: "참여 지수",
                    name: "P Index",
                    title: t("P Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.P_INDEX,
                    queries: {
                        ...(sub_2?.subs[0])?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.P_INDEX,
                    },
                    subs: [],
                    text: t("P Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.P_INDEX,
                }
            ] : [
                {
                    id: null,
                    kr_name: "인기 지수",
                    name: "Popular Index",
                    title: t("Popular Index"),
                    rank_type: CATEGORY_RANK_TYPE.POPULAR,
                    queries: {
                        ...sub_1?.queries,
                        rank_type: CATEGORY_RANK_TYPE.POPULAR
                    },
                    subs: [],
                    text: t("Popular Index"),
                    value: CATEGORY_RANK_TYPE.POPULAR,
                },
                {
                    id: null,
                    kr_name: "이슈 지수",
                    name: "Issue Index",
                    title: t("Issue Index"),
                    rank_type: CATEGORY_RANK_TYPE.ISSUE,
                    queries: {
                        ...sub_1?.queries,
                        rank_type: CATEGORY_RANK_TYPE.ISSUE
                    },
                    subs: [],
                    text: t("Issue Index"),
                    value: CATEGORY_RANK_TYPE.ISSUE,
                },
                {
                    id: null,
                    kr_name: "검색 지수",
                    name: "Search Index",
                    title: t("Search Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX,
                    queries: {
                        ...sub_1?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX,
                    },
                    subs: [],
                    text: t("Search Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX,
                },
                {
                    id: null,
                    kr_name: "뉴스 지수",
                    name: "News Index",
                    title: t("News Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX,
                    queries: {
                        ...sub_1?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX,
                    },
                    subs: [],
                    text: t("News Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX,
                },
                {
                    id: null,
                    kr_name: "바이럴 지수",
                    name: "Viral Index",
                    title: t("Viral Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX,
                    queries: {
                        ...sub_1?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX,
                    },
                    subs: [],
                    text: t("Viral Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX,
                },
                {
                    id: null,
                    kr_name: "참여 지수",
                    name: "P Index",
                    title: t("P Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.P_INDEX,
                    queries: {
                        ...sub_1?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.P_INDEX,
                    },
                    subs: [],
                    text: t("P Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.P_INDEX,
                }
            ];
        } else {
            const sub_current = !!query?.category_level_1 ? subs?.subs?.find((it) => String(it?.id) === String(((!!query?.is_theme || String(path_full).includes('game')) ? query?.category_level_1 : query?.category_level_2) || query?.category_level_1)) : subs;
            m = [
                {
                    id: null,
                    kr_name: "이슈 지수",
                    name: "Issue Index",
                    title: t("Issue Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.TREND_INDEX,
                    level_sub: sub_current?.level_sub || level_sub,
                    queries: {
                        ...sub_current?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.TREND_INDEX
                    },
                    subs: [],
                    text: t("Issue Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.TREND_INDEX,
                },
                {
                    id: null,
                    kr_name: "검색 지수",
                    name: "Search Index",
                    title: t("Search Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX,
                    level_sub: sub_current?.level_sub || level_sub,
                    queries: {
                        ...sub_current?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX,
                    },
                    subs: [],
                    text: t("Search Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX,
                },
                {
                    id: null,
                    kr_name: "뉴스 지수",
                    name: "News Index",
                    title: t("News Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX,
                    level_sub: sub_current?.level_sub || level_sub,
                    queries: {
                        ...sub_current?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX,
                    },
                    subs: [],
                    text: t("News Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX,
                },
                {
                    id: null,
                    kr_name: "바이럴 지수",
                    name: "Viral Index",
                    title: t("Viral Index"),
                    rank_type: CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX,
                    level_sub: sub_current?.level_sub || level_sub,
                    queries: {
                        ...sub_current?.queries,
                        rank_type: CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX,
                    },
                    subs: [],
                    text: t("Viral Index"),
                    value: CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX,
                }];
        }
        return m;
    }, [JSON.stringify(categories), query?.category_id, query?.category_level_1, query?.category_level_2, path_full, menuSideBar, categoryType, i18n?.language]);

    const formatTab = (cate, isClear = true) => {
        const cateChange = {
            category_id: cate?.queries?.level_1_category_id,
            ...((Number(level_sub) <= 1 && isClear) ? { category_level_1: '' } : { category_level_1: cate?.queries?.level_2_category_id }),
            ...(((Number(level_sub) <= 1 || (Number(level_sub) <= 2 && String(path_full).includes('people'))) && isClear) ? { category_level_2: '' } : { category_level_2: (((String(query?.is_theme) === '1') || String(path_full).includes('game')) && !isClear) ? (query?.category_level_2 || '') : (cate?.queries?.level_3_category_id || '') }),
            ...(isClear ? { category_level_3: '' } : { category_level_3: query?.category_level_3 }),
            ...(isClear ? { category_level_4: '' } : { category_level_4: query?.category_level_4 }),
            ...(isClear ? { keyword: '' } : { keyword: query?.keyword }),
            ...({ rank_type: cate?.queries?.rank_type || '' }),
            ...(isClear ? {} : { date: query?.date }),
            ...(isClear ? {} : { is_theme: query?.is_theme }),
            ...(isClear ? {} : { frequency_type: query?.frequency_type }),
            ...(isClear ? {} : { interval_time: query?.interval_time }),
        };

        return cateChange;
    };

    const handleChangeTab = (cate) => {
        // navigate(`/${cate?.path}`);
        const cateChange = formatTab(cate, false);
        resetData(cateChange);
    };

    const handleClickTab = () => {
        setIsShowTheme(true);
    };

    const optionCategoryDropdownByLevelNormal = (level = '') => {
        const sub_1 = optionCategoryDropdown;
        const sub_2 = sub_1?.subs?.find((item) => String(item?.category_id) === String(categoryDropdown?.level_1?.value));
        const sub_3 = sub_2?.subs?.find((item) => String(item?.category_id) === String(categoryDropdown?.level_2?.value));
        const sub_4 = sub_3?.subs?.find((item) => String(item?.category_id) === String(categoryDropdown?.level_3?.value));
        if (level === 'level_1') {
            return sub_1?.subs;
        }
        if (level === 'level_2') {
            return sub_2?.subs;
        }
        if (level === 'level_3') {
            return sub_3?.subs;
        }
        if (level === 'level_4') {
            return sub_4?.subs;
        }
        return [];
    };

    const menuNameTheme = () => {
        const sub_1 = optionCategoryDropdown;
        const sub_2 = sub_1?.subs?.find((item) => String(item?.category_id) === String(query?.category_level_1));
        const sub_3 = sub_2?.subs?.find((item) => String(item?.category_id) === String(query?.category_level_2));
        return sub_3?.name || '';
    };

    useEffect(() => {
        if (!!query?.category_level_1 && String(path_full).includes('corporation')) {
            setIsShowTheme((_prev) => !query?.category_level_2);
        }
    }, [query?.category_level_1, query?.category_level_2, path_full]);

    const handleChangeShowFullColumns = () => {
        setIsShowFullColumns((_prev) => {
            localStorage.setItem("IS_SHOW_FULL_COLUMNS", !_prev)
            return !_prev;
        });
    };

    const handleSearchWhenChangeCategory = (value_change) => {
        const subs = value_change?.subs;
        if (subs) {
            setQuery({
                ...query,
                page: 1,
                sub_category_id: subs?.sub5?.id || subs?.sub4?.id || subs?.sub3?.id || subs?.sub2?.id || subs?.sub1?.id,
                ...(subs?.sub1?.id ? { [`category_level_${Number(subs?.sub1?.depth) - 1}`]: subs?.sub1?.id } : {}),
                ...(subs?.sub2?.id ? { [`category_level_${Number(subs?.sub2?.depth) - 1}`]: subs?.sub2?.id } : {}),
                ...(subs?.sub3?.id ? { [`category_level_${Number(subs?.sub3?.depth) - 1}`]: subs?.sub3?.id } : {}),
                ...(subs?.sub4?.id ? { [`category_level_${Number(subs?.sub4?.depth) - 1}`]: subs?.sub4?.id } : {}),
                ...(subs?.sub5?.id ? { [`category_level_${Number(subs?.sub5?.depth) - 1}`]: subs?.sub5?.id } : {}),
                time_request: + new Date(),
            });
        } else {
            setQuery({
                ...query,
                page: 1,
                sub_category_id: '',
                ...(Number(query?.level_sub) <= 1 ? { [`category_level_1`]: '' } : {}),
                ...(Number(query?.level_sub) <= 2 ? { [`category_level_2`]: '' } : {}),
                ...(Number(query?.level_sub) <= 3 ? { [`category_level_3`]: '' } : {}),
                ...(Number(query?.level_sub) <= 4 ? { [`category_level_4`]: '' } : {}),
                ...(Number(query?.level_sub) <= 5 ? { [`category_level_5`]: '' } : {}),
                time_request: + new Date(),
            });
        }
    }

    return (
        <>
            <div className="row">
                <Tab.Container defaultActiveKey={'AAA'}>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className={`card-header padding-normal border-0 filter-warper cursor-pointer`}>
                                <h4 className="mb-0  d-none d-md-flex align-items-center align-self-start cursor-pointer filter-0 ps-e pe-lg-4" style={{ fontSize: '16px', fontWeight: 900, letterSpacing: '-.4px' }} >
                                    <img src={IMAGES.IconFilter} className="" height="25" width="25" alt="icon filter" style={{ transform: 'rotate(180deg)' }} />
                                </h4>
                                <div className="row filter-row grow-1 w-100 filter-1 mt-0" >
                                    <div className="col-md-12 col-lg-12 col-xl-10 align-self-end">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-3 col-sm-7 col-md-6  mb-xl-0 mb-md-0 mb-3">
                                                <label className='form-label mb-1'>{t('Keyword')}</label>
                                                <input type="text" inputmode="search" className="form-control form-control-sm form-height-38 fs-14" id="exampleFormControlInput1" placeholder={t('Keyword')}
                                                    value={keywordSearch}
                                                    onChange={(e) => {
                                                        setKeywordSearch(String(e.target.value).replace(/[#%&]/g, ''));
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            searchData();
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-sm-5 col-md-6  mb-xl-0 mb-md-0 mb-3">
                                                <label className='form-label mb-1'>{t('Interval')}</label>
                                                <div className="basic-form w-100 d-flex justify-content-space-between align-items-center gap-2" style={{ minHeight: '2.5rem' }}>
                                                    {(INTERVAL_TYPE || []).map((item, ind) => (
                                                        <div className="d-flex align-items-center vertical-align-middle p-0 ps-1" key={ind} onClick={() => {
                                                            setFrequencyTypeSearch((prev) => item);
                                                            setIntervalTimeSearch(INTERVAL_TIME[`${item?.value}`][0]?.value)
                                                        }}>
                                                            <RadioPrimary text={item.label} checked={frequencyTypeSearch?.value === item.value} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={`col-xl-4 col-lg-4 col-sm-7 col-md-6  mb-xl-0 mb-md-3 mb-3 d-flex flex-column align-items-end`}>
                                                <label className='form-label mb-1 w-100'>{t('Date')}</label>
                                                <Nav as="ul" className="nav nav-pills nav-link-custom-select form-height-38">
                                                    {(INTERVAL_TIME[`${(frequencyTypeSearch || INTERVAL_TYPE[0])?.value}`] || []).map((item, ind) => (
                                                        <Nav.Item as="li" className="nav-item flex-grow-1 text-center" key={ind} onClick={() => handleChangeIntervalTime(item.value)}>
                                                            <div className={`nav-link m-0 ${intervalTimeSearch === item.value ? 'active' : ''}`} eventKey={item.label}>
                                                                {item.label}
                                                            </div >
                                                        </Nav.Item>
                                                    ))}
                                                </Nav>
                                            </div>
                                            <div className="col-xl-2 col-lg-2 col-sm-5 col-md-6 mb-1 mb-xl-0 mb-md-3  modal-date order-0 order-sm-2 order-md-0">
                                                <label className='form-label mb-1 d-none d-sm-block' style={{ color: 'transparent', opacity: 0 }}>{t('Date')}</label>
                                                <div className="input-hasicon mb-xl-0 mb-3">
                                                    <RangeDatePicker
                                                        frequencyTypeSearch={frequencyTypeSearch}
                                                        dateSearch={dateSearch}
                                                        handleChangeDatePicker={handleChangeDatePicker}
                                                        intervalTimeSearch={intervalTimeSearch}
                                                    />
                                                    <div className="icon"><i className="far fa-calendar" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-12 col-md-12 align-self-end">
                                        <div className="gap-3 d-flex align-items-center align-items-md-end justify-content-center justify-content-sm-end justify-content-md-start flex-lg-row">
                                            <ButtonPrimary
                                                text={t('Filter')}
                                                onClick={(e) => { e?.stopPropagation(); searchData(); }}
                                                className="btn-search-category"
                                            />
                                            <ButtonSecondary
                                                text={t('Remove Filter')}
                                                onClick={(e) => { e?.stopPropagation(); resetData(); }}
                                                className="btn-search-category"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 mb-3">
                        <BasicTabs tabs={tabs} tabActive={(query?.rank_type)} handleChangeTab={handleChangeTab} />
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className={`card-header border-0 ${!(String(path_full).includes('corporation') || String(path_full).includes('business')) ? 'card-header-filter' : ''}`} >
                                <div className="flex-grow-1 d-flex flex-wrap flex-sm-nowrap align-items-center">
                                    <div className="d-flex justify-content-between align-items-center mb-2  mb-sm-0 div-w-100">
                                        <h4 className={`mb-0 ${(!!query?.is_theme || navigationLevel_1?.name === 'Theme') ? 'cursor-pointer' : ''}`} style={{ fontSize: '16px', fontWeight: 900, letterSpacing: '-.4px' }}
                                            onClick={(e) => { e.preventDefault(); if (!!query?.is_theme || navigationLevel_1?.name === 'Theme') { handleClickTab() } }}
                                        >
                                            {(!!query?.is_theme && !!query?.category_level_2 ? menuNameTheme() : '') || navigationLevel_2?.title || navigationLevel_1?.title || navigationLevel_0?.title || ''}
                                            {(!!query?.is_theme || navigationLevel_1?.name === 'Theme') && <SearchRoundedIcon viewBox="0 0 26 26" className="ms-1 cursor-pointer"
                                            />}
                                        </h4>
                                        <div className="d-block d-sm-none">
                                            <SwitchPrimary placement="start" label={!!isShowFullColumns ? t('Hide columns') : t('Show columns')} checked={!!isShowFullColumns} onChange={handleChangeShowFullColumns} />
                                        </div>
                                    </div>
                                    {!(String(path_full).includes('corporation') || String(path_full).includes('business')) && <><div className="mx-3"><i className="fas fa-chevron-right"></i></div>
                                        <div className="div-col col-sm-8 col-md-3 ">
                                            <DropdownCategory
                                                inputmode="search"
                                                name="category"
                                                placeholder={t('Category')}
                                                isClearable={true}
                                                dataList={optionCategoryDropdownByLevelNormal(!!query?.level_sub ? `level_${query?.level_sub}` : 'level_1')}
                                                initialValue={sub_category_id ? categorySearch : null}
                                                onChangeCategory={(event) => {
                                                    setCategorySearch(event);
                                                    handleSearchWhenChangeCategory(event);
                                                }}
                                                disabled={String(path_full).includes(PATH_CATEGORY_GROUP_TYPE[`${CATEGORY_GROUP_TYPE.BUSINESS}`]) || String(path_full).includes(PATH_CATEGORY_GROUP_TYPE[`${CATEGORY_GROUP_TYPE.CORPORATION}`])}
                                            />
                                        </div></>}
                                    <div className="mt-3 mt-sm-0 ms-0 ms-sm-3 div-w-100 d-block d-sm-none d-lg-block">
                                        {t('Total Keywords')} : {(isKeywordLoading && Number(query?.page) === 1) ? <Spinner size="sm" style={{ width: '1rem', height: '1rem' }} /> : <>{formatNumberWithCommas(keywords?.total || 0)}</>}
                                    </div>
                                </div>
                                <div className="tools d-none d-sm-block">
                                    <SwitchPrimary placement="start" label={!!isShowFullColumns ? t('Hide columns') : t('Show columns')} checked={!!isShowFullColumns} onChange={handleChangeShowFullColumns} />
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="col-12 mt-0 mb-3 d-none d-sm-block d-lg-none">
                                    {t('Total Keywords')} : {(isKeywordLoading && Number(query?.page) === 1) ? <Spinner size="sm" style={{ width: '1rem', height: '1rem' }} /> : <>{formatNumberWithCommas(keywords?.total || 0)}</>}
                                </div>
                                <div className="border-card-table" style={{ height: '100%', borderBottom: '1px solid var(--border)' }}>
                                    <InfiniteScroll
                                        dataLength={keywords?.list?.length || 0}
                                        next={handleLoadMore}
                                        scrollableTarget='scrollableDiv'
                                        hasMore={isLoadMore}
                                        loader={''}
                                        scrollThreshold={'50%'}
                                    >
                                        <div className="cm-content-body form excerpt position-relative">
                                            <div className="card-body py-3 px-0 pt-0 pb-0">
                                                <div>
                                                    <div>
                                                        <TableContainer
                                                            data={keywords?.list || []}
                                                            query={query}
                                                            intervalTimeSearch={query?.interval_time}
                                                            frequencyType={query?.frequency_type}
                                                            categoryType={optionCategoryDropdown?.type}
                                                            categoryGroupType={String(path_full).includes(PATH_CATEGORY_GROUP_TYPE[`${CATEGORY_GROUP_TYPE.PEOPLE}`]) ? CATEGORY_GROUP_TYPE.PEOPLE : ''}
                                                            typeShowParticipation={typeShowParticipation}
                                                            sortColumnTableRanking={GET_SORT_COLUMN_TABLE_RANKING}
                                                            isCollapse={isShowFullColumns}
                                                            setIsCollapse={handleChangeShowFullColumns}
                                                            handleChangeSorting={handleChangeSorting}
                                                            handleVote={handleSetKeywordVote}
                                                            handleSurvey={handleSetKeywordSurvey}
                                                            handleShowMore={handleShowMore}
                                                            handleSetKeywordDetail={handleSetKeywordDetail}
                                                            handleSetKeywordInfo={handleSetKeywordInfo}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </InfiniteScroll>
                                    <Loading isLoading={isKeywordLoading} className="mb-3" textEndLoading={keywords?.list?.length > 0 ? (!!user?.user?.id ? t('A plan upgrade is required to view the following list') : t('No more data')) : t('No data')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            </div>
            <div>
                <ModalVoting isShow={!!isShowModalVoting} isLoading={isLoading} setChargeInfo={handleSetChargeInfo} keywordVote={keywordVote} tickets={tickets} myTicket={myTicket} ticketAmountVote={ticketAmountVote} setTicketAmountVote={handleSetTicketAmountVote} handleVote={handleVote} handlePollVote={handlePollVote} onClose={handleCloseVote} />
                <ModalPurchase isLoading={isLoading} chargeInfo={chargeInfo} setChargeInfo={handleSetChargeInfo} tickets={tickets} myTicket={myTicket} isShow={!!chargeInfo} onClose={handleCloseChargeInfo} handlePurchase={handlePurchase} />
                <ModalNotiVote isLoading={isLoading} keywordVotedInfo={keywordVotedInfo} setKeywordVotedInfo={setKeywordVotedInfo} isShow={!!keywordVotedInfo} />
                {/* <ModalDragDropColumn isShow={!!sortColumns} onClose={() => setSortColumns(null)} onUpdate={handleUpdateSortColumnTableRanking} /> */}
                <ModalKeywordDetail dataDefault={keywordDetail} isShow={!!keywordDetail} query={query} frequencyType={query?.frequency_type} onClose={handleCloseKeywordDetail} />
                <ModalInfoKeyword isShow={!!keywordInfo} keyword={keywordInfo} onClose={handleCloseKeywordInfo} />
                <ModalSurvey surveyForm={surveyForm} surveyDetail={surveyDetail} isShow={!!surveyDetail} onClose={handleCloseSurveyDetail} />
                <ModalSubcategory isShow={!!categoriesLevel} level_sub={categoriesLevel?.level_sub} categories={categoriesLevel?.categories} onClose={() => setCategoriesLevel(null)} />
                <ModalGoToLogin isShow={!!confirmLogin} onClose={() => setConfirmLogin((_prev) => false)} goToLogin={goToLogin} />
                <ModalTheme parentCategoryId={query?.category_level_1} themeCategoryId={query?.category_level_2} isShow={!!isShowTheme} searchByTheme={searchByTheme} onClose={handleCloseTheme} />
            </div>
        </>
    )
}
export default Category;