import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import NoImage from '../../../../../assets/images/no-photo-available.png';

const GolfPlayer = ({ keyword_info = {} }: any) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column gap-3 w-100">
                    <>
                        <div style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.thumbnail || keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    // backgroundColor: '#fff'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {keyword_info?.name || ''}
                                    <span className="fs-16 ms-2">{keyword_info?.rank || ''}위</span>
                                </h3>
                            </div>
                            <table className="table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('League Code')}</td>
                                        <td className="text-start align-middle py-2">{keyword_info?.league_code || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Code')}</td>
                                        <td className="text-start align-middle py-2">{keyword_info?.code || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-2 bg-secondary-100" width="152px">{t('Country')}</td>
                                        <td className="text-start align-middle py-2">{keyword_info?.country || '-'}</td>
                                    </tr>
                                </tbody>
                            </table >
                        </div >
                    </>
                </div >
                <div className="w-100 mt-2">
                    <div className="w-100">
                        <table className="table table-bordered mb-0">
                            <tbody>
                                <tr>
                                    <td className="text-center align-middle py-2 bg-secondary-100" rowSpan={7} width="152px">
                                        <h6 className="mb-0">{i18n?.language === 'en' ? t('Season') : ''} {keyword_info?.season || ''} {i18n?.language === 'ko' ? t('Season') : ''}</h6>
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {t('Prize Money')}
                                    </td>
                                    <td className="text-center align-middle py-2" style={{ minWidth: '80px' }}>
                                        {keyword_info?.reward}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Genesis Points')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.genesis_point}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Driving Distance')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.driving_distance}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Greens in Regulation')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.greens_in_regulation}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Average Putting')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.avg_putts}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Fairway Hit Percentage')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.driving_acc}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Top 10 Finish')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.top_10_finish}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(GolfPlayer);