import { formatNumberWithCommas } from 'helpers/format';
import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import { useTranslation } from 'react-i18next';

interface ChartToBase64Props {
    data?: { date: string; index: number }[];
    name?: string;
    height?: number;
    showAxisBorder?: boolean;
    fillColor?: string;
    fillOpacity?: number;
    isRotate?: boolean;
    markersSize?: number;
    style?: React.CSSProperties;
    isShowXaxis?: boolean;
    isShowYaxis?: boolean;
    isShowTooltip?: boolean;
    tickAmountXaxis?: number;
}

const ChartToBase64: React.FC<ChartToBase64Props> = ({
    data = [],
    name = '',
    height = 70,
    showAxisBorder = false,
    fillColor = '#fb3335',
    fillOpacity = 0.5,
    isRotate = false,
    markersSize = 0,
    style = { transform: 'translateY(-20px)' },
    isShowXaxis = false,
    isShowYaxis = false,
    isShowTooltip = false,
    tickAmountXaxis = 5
}) => {
    const { t, i18n } = useTranslation();
    const chartContainerRef = useRef<HTMLDivElement | null>(null);
    const [base64Image, setBase64Image] = useState<string | null>(null);

    const categories = data.map(item => item.date);
    const values = data.map(item => item.index);

    const series: any = [
        {
            name: name,
            data: values,
        },
    ];

    const options: any = {
        chart: {
            type: 'area',
            height: '100%',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            animations: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                endingShape: 'rounded',
            },
        },
        grid: {
            show: false, // Hide the grid completely
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }
        },
        stroke: {
            show: true,
            width: 2,
            curve: 'straight',
            lineCap: 'butt',
            colors: [fillColor],
        },
        markers: {
            size: [markersSize],
            colors: 'var(--card)',
            strokeColors: fillColor,
            strokeWidth: 2,
        },
        xaxis: {
            categories: categories,
            labels: {
                show: isShowXaxis,
                style: {
                    colors: '#030012',
                    fontSize: '11px',
                    fontFamily: "NanumSquare",
                    fontWeight: 100,
                },
                ...(isRotate ? {} : { rotate: 0 }),
            },
            crosshairs: {
                show: false, // Ensure crosshairs are disabled on x-axis
            },
            axisBorder: {
                show: false, // Remove the axis border
            },
            axisTicks: {
                show: false, // Remove the axis ticks
            },
            tickAmount: tickAmountXaxis,
        },
        yaxis: {
            show: isShowYaxis,
            axisBorder: {
                show: false, // Remove the axis border
            },
            axisTicks: {
                show: false, // Remove the axis ticks
            },
            crosshairs: {
                show: false, // Ensure crosshairs are disabled on x-axis
            },
            labels: {
                show: isShowYaxis,
                offsetX: -15,
                style: {
                    colors: fillColor,
                    fontSize: '11px',
                    fontFamily: "NanumSquare",
                    fontWeight: 100,
                },
                formatter: (y: number) => formatNumberWithCommas(y),
            },
        },
        legend: {
            show: false, // Hide the legend
        },
        dataLabels: {
            enabled: false, // Disable data labels
        },
        fill: {
            opacity: fillOpacity,
            colors: fillColor,
            ...(fillOpacity === 0 ? { type: 'solid' } : {}),
        },
        tooltip: {
            enabled: isShowTooltip,
            theme: 'light',
            y: {
                formatter: (val: number) => formatNumberWithCommas(val),
            },
            x: {
                show: false // Disable tooltip crosshairs on x-axis
            },
            marker: {
                show: false // Disable the marker in the tooltip
            },
        },
        // Remove crosshair settings
        crosshairs: {
            show: false // Disable crosshairs globally if supported
        },
    };



    useEffect(() => {
        const convertSvgToBase64 = () => {
            const svgElement = chartContainerRef.current?.querySelector('svg');
            if (svgElement) {
                const svgData = new XMLSerializer().serializeToString(svgElement);
                const base64 = window.btoa(unescape(encodeURIComponent(svgData))); // Convert to base64 directly
                setBase64Image(`data:image/svg+xml;base64,${base64}`);
            }
        };

        // Add a small delay to make sure the chart is fully rendered
        const timeoutId = setTimeout(convertSvgToBase64, 1000);

        return () => clearTimeout(timeoutId); // Clean up the timeout
    }, [series, options]);

    return (
        <div ref={chartContainerRef}>
            {base64Image ? (
                <img src={base64Image} alt="Base64 Chart" style={style} />
            ) : (
                <ReactApexChart
                    options={options}
                    series={series}
                    type="area"
                    height={height}
                    style={style}
                />
            )}
        </div>
    );
};

export default React.memo(ChartToBase64);
