import moment from "moment";
import { CATEGORY_INDEX_RANK_TYPE, CATEGORY_RANK_TYPE, FREQUENCY_TYPE, SORT_COLUMN_TABLE_RANKING } from "./constans";

export const formatNumber = (num: number) => {
    return new Intl.NumberFormat('en-IN').format(num);
};

export const formatNumberWithCommas = (number: number | string = 0) => {
    if (String(number) === 'NaN') {
        return 0;
    }
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNumberWithoutCommas = (number: number | string = 0) => {
    return parseFloat(String(number).replace(/,/g, ''));
};

export const formatDateStringTo2Row = (date: string = '') => {
    const arr = String(date).split(' ');
    return `${arr[0]} <br/> ${arr[1]}`;
};

export const formatQueryParams = (query: any = {}) => {
    const clone = structuredClone(query);
    delete clone.time_request;
    delete clone.time_request_out;
    delete clone.time_request_inner;
    delete clone.category_level_1;
    delete clone.category_level_2;
    delete clone.category_level_3;
    delete clone.category_level_4;
    delete clone.interval_time;
    delete clone.is_clear;
    delete clone.is_theme;
    delete clone.level_sub;
    return clone;
};

export const formatLinkHTTPS = (linkString: string = '') => {
    if ((String(linkString).includes('https://') || String(linkString).includes('http://'))) {
        return linkString;
    }
    return `https://${linkString || ''}`;
};

export function isAfter4PMKoreanTime() {
    const currentTime = new Date();
    const currentHour = currentTime.getUTCHours(); // Get the current hour in UTC

    // Convert to Korean time zone (UTC+9)
    const koreanTimeHour = currentHour + 9; // UTC+9

    // Check if it's after 16:00 in Korean time
    if (koreanTimeHour >= 16) {
        return true;
    } else {
        return false;
    }
};

export const renderUrlSearchLookingUpByIP = (ip: string = '') => {
    return `https://whatismyipaddress.com/ip/${ip}`;
};

export const replaceHalfWithStar = (inputString: string) => {
    var length = inputString.length;
    var halfLength = Math.floor(length / 2);
    var starsCount = length - halfLength;
    var replacedString = inputString.substring(0, halfLength) + '*'.repeat(starsCount);
    return replacedString;
};

export const formatHour = (hour: number) => {
    if (hour < 0 || hour > 23) {
        return 'Invalid hour';
    }

    let startHour = hour.toString().padStart(2, '0') + ":00";

    let endHour: any = hour + 1;
    if (endHour === 24) {
        endHour = "24:00";
    } else {
        endHour = endHour.toString().padStart(2, '0') + ":00";
    }

    return `${startHour} ~ ${endHour}`;
};

export const renderScriptURLOfInventory = (script_url: string = '', is_wrapper: boolean = false) => {
    if (is_wrapper) {
        return `<div id="ahtm-banner" style="width: 100%"><script src="${script_url}"></script></div>`;
    }
    return `<script src="${script_url}"></script>`;
};

export const formatCalculatePercentageGender = (data: any = {}) => {
    const { search_age_10_value, search_age_20_value, search_age_30_value, search_age_40_value, search_age_50_value } = data;
    const ob = { search_age_10_value, search_age_20_value, search_age_30_value, search_age_40_value, search_age_50_value };
    const total: any = Object.values(ob).reduce((acc: any, value: any) => acc + value, 0);

    const entries = Object.entries(ob);

    const percentages: any = {};
    let accumulatedPercentage = 0;

    for (let i = 0; i < entries.length - 1; i++) {
        const [key, value]: any = entries[i];
        const percentage: any = Number(total) === 0 ? 0 : ((value / total) * 100).toFixed(2);
        percentages[key] = `${percentage}%`;
        accumulatedPercentage += parseFloat(percentage);
    }

    const lastKey = entries[entries.length - 1][0];
    const lastPercentage = Number(total) === 0 ? 0 : (100 - accumulatedPercentage).toFixed(2);
    percentages[lastKey] = `${lastPercentage}%`;

    return percentages;
};

export const formatCalculatePercentageAge = (data: any = {}) => {
    const { search_male_value, search_female_value } = data;
    const ob = { search_male_value, search_female_value };
    const total: any = Object.values(ob).reduce((acc: any, value: any) => acc + value, 0);

    const entries = Object.entries(ob);

    const percentages: any = {};
    let accumulatedPercentage = 0;

    for (let i = 0; i < entries.length - 1; i++) {
        const [key, value]: any = entries[i];
        const percentage: any = Number(total) === 0 ? 0 : ((value / total) * 100).toFixed(2);
        percentages[key] = `${percentage}%`;
        accumulatedPercentage += parseFloat(percentage);
    }

    const lastKey = entries[entries.length - 1][0];
    const lastPercentage = Number(total) === 0 ? 0 : (100 - accumulatedPercentage).toFixed(2);
    percentages[lastKey] = `${lastPercentage}%`;

    return percentages;
};

export const getDateRange = (type_picker: any, date_string: any) => {
    const date = moment(date_string);
    if (String(type_picker) === String(FREQUENCY_TYPE.WEEKLY)) {
        const startDateOfWeek = date.clone().startOf('isoWeek');
        const endDateOfWeek = date.clone().endOf('isoWeek');
        return [startDateOfWeek.format('YYYY-MM-DD'), endDateOfWeek.format('YYYY-MM-DD')];
    } else if (String(type_picker) === String(FREQUENCY_TYPE.MONTHLY)) {
        const startDateOfMonth = date.clone().startOf('month');
        const endDateOfMonth = date.clone().endOf('month');
        return [startDateOfMonth.format('YYYY-MM-DD'), endDateOfMonth.format('YYYY-MM-DD')];
    } else {
        return [date_string, date_string];
    }
};

export const getSortColumnTableRanking = (path_full = '', rank_type = '') => {

    return [
        { uuid: 'rank', isShow: true, isDisabled: true, isDragDisabled: false },
        { uuid: 'keyword', isShow: true, isDisabled: true, isDragDisabled: false },
        { uuid: 'user_participation', isShow: true, isDisabled: true, isDragDisabled: false },
        { uuid: 'index_chart', isShow: true, isDisabled: true, isDragDisabled: false },

        ...(String(path_full)?.includes('people') ? [{ uuid: 'category', isShow: true, isDisabled: false, isDragDisabled: false }] : []),
        ...(String(path_full)?.includes('people') ? [{ uuid: 'subcategory', isShow: true, isDisabled: false, isDragDisabled: false }] : []),

        ...(String(rank_type) === String(CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX) ? [{ uuid: 'search_index', isShow: true, isFixed: true, isDisabled: false, isDragDisabled: false }] : []),
        ...(String(rank_type) === String(CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX) ? [{ uuid: 'news_index', isShow: true, isFixed: true, isDisabled: false, isDragDisabled: false }] : []),
        ...(String(rank_type) === String(CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX) ? [{ uuid: 'viral_index', isShow: true, isFixed: true, isDisabled: false, isDragDisabled: false }] : []),
        ...(String(rank_type) === String(CATEGORY_INDEX_RANK_TYPE.P_INDEX) ? [{ uuid: 'p_index', isShow: true, isFixed: true, isDisabled: false, isDragDisabled: false }] : []),

        // { uuid: 'popular_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false },
        ...(String(rank_type) === String(CATEGORY_RANK_TYPE.POPULAR) ? [{ uuid: 'popular_index', isShow: true, isFixed: Number(rank_type || 0) <= 1, isDisabled: true, isDragDisabled: false }] : []),
        ...(String(rank_type) === String(CATEGORY_INDEX_RANK_TYPE.TREND_INDEX) ? [{ uuid: 'trend_index', isShow: true, isFixed: Number(rank_type || 0) >= 2 && Number(rank_type || 0) <= 3, isDisabled: true, isDragDisabled: false }] : []),
        // { uuid: 'trend_index', isShow: true, isFixed: Number(rank_type || 0) <= 3, isDisabled: true, isDragDisabled: false },

        ...(!(String(path_full)?.includes('people')) ? [{ uuid: 'category', isShow: true, isDisabled: false, isDragDisabled: false }] : []),
        ...(!(String(path_full)?.includes('people')) ? [{ uuid: 'subcategory', isShow: true, isDisabled: false, isDragDisabled: false }] : []),

        ...(String(rank_type) !== String(CATEGORY_RANK_TYPE.POPULAR) ? [{ uuid: 'popular_index', isShow: true, isFixed: Number(rank_type || 0) <= 1, isDisabled: true, isDragDisabled: false }] : []),
        ...(String(rank_type) !== String(CATEGORY_INDEX_RANK_TYPE.TREND_INDEX) ? [{ uuid: 'trend_index', isShow: true, isFixed: Number(rank_type || 0) >= 2 && Number(rank_type || 0) <= 3, isDisabled: true, isDragDisabled: false }] : []),

        ...(String(rank_type) !== String(CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX) ? [{ uuid: 'search_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false }] : []),
        ...(String(rank_type) !== String(CATEGORY_INDEX_RANK_TYPE.P_INDEX) ? [{ uuid: 'p_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false }] : []),
        { uuid: 'stock_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false },
        { uuid: 'activity_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false },
        ...(String(rank_type) !== String(CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX) ? [{ uuid: 'viral_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false }] : []),
        ...(String(rank_type) !== String(CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX) ? [{ uuid: 'news_index', isShow: true, isFixed: false, isDisabled: false, isDragDisabled: false }] : []),

    ];

}

export const setSortColumnTableRanking = (data: any = []) => {
    localStorage.setItem('SORT_COLUMN_TABLE_RANKING', JSON.stringify(data));
}