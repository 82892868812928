import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import NoImage from '../../../../../assets/images/no-photo-available.png';

const SoccerPlayer = ({ keyword_info = {} }: any) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="d-flex flex-w flex-wrap mb-3">
                <div className="d-flex flex-column gap-3 w-100">
                    <>
                        <div style={{ width: '100%', height: 'auto', maxHeight: '300px' }}>
                            <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.thumbnail || keyword_info?.image_url || keyword_info?.image || NoImage}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                    // backgroundColor: '#fff'
                                }}
                            />
                        </div>
                        <div className="w-100">
                            <div>
                                <h3 className="mb-1">
                                    {keyword_info?.name || ''}
                                    <span className="fs-16 ms-2">No.{keyword_info?.no || ''}</span>
                                </h3>
                            </div>
                            <table className="table table-bordered mb-0">
                                <tbody>
                                    <tr>
                                        <td className="text-start align-middle py-1 bg-secondary-100" width="152px" >{t('Team')}</td>
                                        <td className="text-start align-middle py-1">{keyword_info?.team || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-1 bg-secondary-100" width="152px">{t('Birthday')}</td>
                                        <td className="text-start align-middle py-1">{keyword_info?.birthday || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-1 bg-secondary-100" width="152px">{t('Position')}</td>
                                        <td className="text-start align-middle py-1">{keyword_info?.position || '-'}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-1 bg-secondary-100" width="152px">{t('Height')}</td>
                                        <td className="text-start align-middle py-1">{keyword_info?.height || '-'}cm</td>
                                    </tr>
                                    <tr>
                                        <td className="text-start align-middle py-1 bg-secondary-100" width="152px">{t('Weight KG')}</td>
                                        <td className="text-start align-middle py-1">{keyword_info?.weight || '-'}kg</td>
                                    </tr>
                                </tbody>
                            </table >
                        </div >
                    </>
                </div >
                <div className="w-100 mt-2">
                    <div className="w-100">
                        <table className="table table-bordered mb-0">
                            <tbody>
                                <tr>
                                    <td className="text-center align-middle py-2 bg-secondary-100" rowSpan={8} width="152px">
                                        <h6 className="mb-0">{i18n?.language === 'en' ? t('Season') : ''} {keyword_info?.season || ''} {i18n?.language === 'ko' ? t('Season') : ''}</h6>
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {t('Matches (Games)')}
                                    </td>
                                    <td className="text-center align-middle py-2" style={{ minWidth: '80px' }}>
                                        {keyword_info?.player_stats?.game}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Goals')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.player_stats?.goal}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Assists')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.player_stats?.assist}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Attack Points')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.player_stats?.attack_point}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Shots')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.player_stats?.shot}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Shots on Target')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.player_stats?.shot_on_target}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Goals per Game')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.player_stats?.avg_goal_per_game}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center align-middle py-2">
                                        {t('Minutes Played')}
                                    </td>
                                    <td className="text-center align-middle py-2">
                                        {keyword_info?.player_stats?.playing_time}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        </>
    )
}

export default React.memo(SoccerPlayer);