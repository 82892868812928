import { createAsyncThunk } from "@reduxjs/toolkit";
import rankingApi from "api/rankingApi";
import { CATEGORY_INDEX_RANK_TYPE, CATEGORY_RANK_TYPE } from "helpers/constans";
import { formatQueryParams } from "helpers/format";
let abortController: AbortController | null = null;

export const rankingCategory = async (params: any = {}) => {
  try {
    const response = await rankingApi.rankingCategory(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};
export const rankingCategories = createAsyncThunk("Ranking Categories", async (params: any = {}) => {
  try {
    const response = await rankingApi.rankingCategory(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const rankingKeywords = createAsyncThunk("Ranking Keywords By Category", async (params: any = {}) => {
  try {
    const response = await rankingApi.rankingKeywordByCategory(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});


export const getRankingKeywordsByNormal = async (params: any = {}) => {
  try {
    const clone = structuredClone(params);

    if (!!clone?.sort_by && clone?.sort_by === 'trend_index') {
      clone.sort_by = 'index';
    }

    if (!!clone?.rank_type) {
      if ([String(CATEGORY_RANK_TYPE.ISSUE), String(CATEGORY_INDEX_RANK_TYPE.TREND_INDEX)]?.includes(String(clone?.rank_type))) {
        clone.index_tab_type = 'issue_index';
      }
      if (String(clone?.rank_type) === String(CATEGORY_RANK_TYPE.POPULAR)) {
        clone.index_tab_type = 'popular_index';
      }
      if (String(clone?.rank_type) === String(CATEGORY_INDEX_RANK_TYPE.SEARCH_INDEX)) {
        clone.index_tab_type = 'search_index';
      }
      if (String(clone?.rank_type) === String(CATEGORY_INDEX_RANK_TYPE.NEWS_INDEX)) {
        clone.index_tab_type = 'news_index';
      }
      if (String(clone?.rank_type) === String(CATEGORY_INDEX_RANK_TYPE.VIRAL_INDEX)) {
        clone.index_tab_type = 'viral_index';
      }
      if (String(clone?.rank_type) === String(CATEGORY_INDEX_RANK_TYPE.P_INDEX)) {
        clone.index_tab_type = 'p_index';
      }
    }

    if (!!clone?.rank_type && ![String(CATEGORY_RANK_TYPE.POPULAR), String(CATEGORY_RANK_TYPE.ISSUE)]?.includes(String(clone?.rank_type))) {
      clone.rank_type = CATEGORY_RANK_TYPE.POPULAR;
    }

    const response = await rankingApi.rankingKeywordByCategory(formatQueryParams(clone))
    return response;
  } catch (error) {
    return error;
  }
};


export const getRankingKeywordByNormal = async (params: any = {}) => {
  try {
    const response = await rankingApi.rankingKeyword(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};