import { FREQUENCY_TYPE } from 'helpers/constans';
import { formatCalculatePercentageAge, formatCalculatePercentageGender } from 'helpers/format';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Collapse, Container, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getRankingKeywordByNormal } from 'store/thunks';
import ButtonPrimary from 'ui/components/buttons/ButtonPrimary';
import RadioPrimary from 'ui/components/buttons/RadioPrimary';
import RangeDatePickerNormal from 'ui/components/pickers/RangeDatePickerNormal';
import RankingChart from '../Table/RankingChart';

const ModalKeywordDetail = ({ dataDefault, query = {}, isShow = false, onClose }: any) => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const user = useSelector((state: any) => state?.Login);

    const handleChangeDatePicker = (frequency_type: any, start_date: any, end_date: any) => {
        const frequencyValue = String(frequency_type);
        switch (frequencyValue) {
            case String(FREQUENCY_TYPE.WEEKLY):
                const start = String(start_date)?.split(' - ')[0];
                const end = String(end_date)?.split(' - ')[1];
                return [moment(start).toDate(), moment(end).toDate()];
            case String(FREQUENCY_TYPE.MONTHLY):
            case String(FREQUENCY_TYPE.DAILY):
                return [moment(start_date).toDate(), moment(end_date).toDate()];
            default:
                return [null, null];
        }
    }

    const formatEndDate = (frequency_type: any, date: any) => {
        const frequencyValue = String(frequency_type);
        switch (frequencyValue) {
            case String(FREQUENCY_TYPE.MONTHLY):
                return moment(date).endOf('month').format("Y-MM-DD");
            case String(FREQUENCY_TYPE.WEEKLY):
            case String(FREQUENCY_TYPE.DAILY):
                return moment(date).format("Y-MM-DD");
            default:
                return moment(date).format("Y-MM-DD");
        }
    }

    const INTERVAL_TYPE = [{ label: t('Daily'), value: 1 }, { label: t('Weekly'), value: 2 }, { label: t('Monthly'), value: 3 }];

    const [isLoading, setIsLoading] = useState(false);

    const [openFilter, setOpenFilter] = useState(false);

    const [frequencyType, setFrequencyType] = useState(String(query?.frequency_type));

    const [data, setData] = useState(dataDefault || {});

    const [dateSearch, setDateSearch] = useState(handleChangeDatePicker(query?.frequency_type, dataDefault?.ranking_changes_chart?.[0]?.date, dataDefault?.ranking_changes_chart[dataDefault?.ranking_changes_chart?.length - 1]?.date)); // query?.date

    const [frequencyTypeSearch, setFrequencyTypeSearch] = useState<any>(INTERVAL_TYPE.find((item) => String(item?.value) === String(query?.frequency_type)) || INTERVAL_TYPE[0]);

    const calculatePercentageAge = formatCalculatePercentageAge(data);

    const calculatePercentageGender = formatCalculatePercentageGender(data);

    const handleDuplicateCategory = (categories: any[] = []) => {
        return categories?.reduce((ob, item) => {
            if (ob?.listId?.includes(item?.name)) {
                return ob;
            }
            return ({ listId: [...ob?.listId, item?.name], listName: [...ob?.listName, item?.name] });
        }, { listId: [], listName: [] });
    }
    const categories_level_2 = handleDuplicateCategory(data?.sub_category)?.listName || [];
    const categories_level_3 = handleDuplicateCategory(data?.sub_sub_category)?.listName || [];

    const handleChangePicker = (dates: any) => {
        setDateSearch((_prev: any) => dates);
    }

    const handleChangePickerDefault = (frequency_type: any) => {
        const frequencyValue = String(frequency_type);
        switch (frequencyValue) {
            case String(FREQUENCY_TYPE.MONTHLY):
                setDateSearch((_prev: any) => [moment().subtract(6, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()]);
                break;
            case String(FREQUENCY_TYPE.WEEKLY):
                setDateSearch((_prev: any) => [moment().subtract(12, 'weeks').startOf('isoWeek').toDate(), moment().subtract(1, 'weeks').endOf('isoWeek').toDate()]);
                break;
            default:
                setDateSearch((_prev: any) => [moment().subtract(32, 'days').toDate(), moment().subtract(2, 'days').toDate()]);
                break;
        }
    }

    const handleQueryData = async () => {
        try {
            setIsLoading((prev) => true);
            const res: any = await getRankingKeywordByNormal({
                category_id: query?.category_id,
                sub_category_id: query?.sub_category_id,
                keyword_id: data?.keyword_id || '',
                start_date: dateSearch[0] ? moment(dateSearch[0]).format("Y-MM-DD") : '',
                end_date: dateSearch[1] ? formatEndDate(frequencyTypeSearch?.value || INTERVAL_TYPE[0]?.value, dateSearch[1]) : '',
                frequency_type: frequencyTypeSearch?.value || INTERVAL_TYPE[0]?.value,
            });
            setData((_prev: any) => ({ ..._prev, is_search: true, ranking_changes_chart: res?.data || [] }));
            setFrequencyType((_prev: any) => (frequencyTypeSearch?.value || INTERVAL_TYPE[0]?.value));
            setIsLoading((prev) => false);
        } catch (error) {
            setIsLoading((prev) => false);
        }
    };

    const onHide = () => {
        setOpenFilter((_prev) => false);
        onClose && onClose();
    };

    useEffect(() => {
        if (dataDefault) {
            setData((_prev: any) => dataDefault);
            setDateSearch((_prev: any) => handleChangeDatePicker(query?.frequency_type, dataDefault?.ranking_changes_chart?.[0]?.date, dataDefault?.ranking_changes_chart[dataDefault?.ranking_changes_chart?.length - 1]?.date));
            setFrequencyType((_prev: any) => (String(query?.frequency_type)));
            setFrequencyTypeSearch(INTERVAL_TYPE.find((item) => String(item?.value) === String(query?.frequency_type)) || INTERVAL_TYPE[0]);
        }
    }, [JSON.stringify(dataDefault)]);

    return (
        <>
            <Modal id="modal-keyword-detail" className="fade bd-example-modal-lg" show={!!isShow} centered size="xl" onHide={onHide}>
                <Modal.Header className="border-0 pt-4">
                    <Modal.Title>{t('Keyword Analysis')}</Modal.Title>
                    <svg width="24" height="24" viewBox="0 0 24 24" className="cursor-pointer" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onHide}>
                        <path d="M18 18L6 6M18 6L6 18" stroke="#47494E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Modal.Header>
                <Modal.Body className="pb-2 pt-0">
                    <Container className="px-0 mb-4" style={{ minHeight: '80dvh' }}>
                        <div style={{
                            // backgroundColor: '#f3f6f9',
                            // padding: '20px',
                            // borderRadius: '8px',
                        }}>
                            <Row>
                                <Col sm="12" className="mb-3 mb-sm-4 ">
                                    <Card className="mb-0 rounded-2" style={{ height: '100%', boxShadow: 'none', border: 'var(--bs-border-width) solid var(--border)' }}>
                                        <Card.Header className="p-2 p-lg-3 cursor-pointer" style={{ borderBottom: '0px' }}>
                                            <Card.Title style={{ fontSize: '14px' }} className="d-flex align-items-center">
                                                <span className="tag-keyword">{t('Keyword')}</span>
                                                <span className="fs-20">{data?.keyword?.keyword || ''}</span>
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body className="p-2 p-lg-3 mb-3 mb-lg-2 pt-2 pt-lg-0 mt-0">
                                            <Table bordered className="mb-0 table-detail-keyword">
                                                <tbody>
                                                    <tr>
                                                        <td className="p-2 text-center fs-14" style={{
                                                            width: '130px',
                                                            backgroundColor: '#f8f9fb',
                                                            fontWeight: 500,
                                                            color: 'var(--text-dark)',
                                                            borderRight: '0px solid #fff'
                                                        }}>
                                                            {t('Category')}
                                                        </td>
                                                        <td className="p-2 py-2 text-start fs-13 d-flex flex-wrap gap-1">
                                                            {!categories_level_2 && !categories_level_3 ? '-' : ''}
                                                            {data?.category_level_1 && (
                                                                <span className={`mb-0 fs-12 fw-600 me-2 text-secondary`}
                                                                    style={{
                                                                        padding: '5px 14px',
                                                                        borderRadius: '17px',
                                                                        boxSizing: 'border-box',
                                                                        verticalAlign: 'middle',
                                                                        fontSize: '14px',
                                                                        backgroundColor: `rgba(var(--bs-secondary-rgb), 0.1)`,
                                                                    }}
                                                                >
                                                                    {data?.category_level_1?.name || ''}
                                                                </span>
                                                            )}
                                                            {(categories_level_2?.map((cate: any, i: number) => (
                                                                <span className={`mb-0 fs-12 fw-600 me-2 text-success`}
                                                                    style={{
                                                                        padding: '5px 14px',
                                                                        borderRadius: '17px',
                                                                        boxSizing: 'border-box',
                                                                        verticalAlign: 'middle',
                                                                        fontSize: '14px',
                                                                        backgroundColor: `rgba(var(--bs-success-rgb), 0.1)`,
                                                                    }}
                                                                >
                                                                    {cate || ''}
                                                                </span>
                                                            )))}
                                                            {(categories_level_3?.map((cate: any, i: number) => (
                                                                <span className={`mb-0 fs-12 fw-600 me-2 text-success`}
                                                                    style={{
                                                                        padding: '5px 14px',
                                                                        borderRadius: '17px',
                                                                        boxSizing: 'border-box',
                                                                        verticalAlign: 'middle',
                                                                        fontSize: '14px',
                                                                        backgroundColor: `rgba(var(--bs-success-rgb), 0.1)`,
                                                                    }}
                                                                >
                                                                    {cate || ''}
                                                                </span>
                                                            )))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm="12" className="mb-3 mb-sm-4 ">
                                    <Card className="mb-0 rounded-2" style={{ height: '100%', border: 'var(--bs-border-width) solid var(--border)', boxShadow: 'none' }}>
                                        <Card.Header className="p-2 p-lg-3 cursor-pointer"
                                            style={{ borderBottomWidth: '0px' }}
                                            onClick={(e) => { e.preventDefault(); setOpenFilter(!openFilter); }}>
                                            <Card.Title style={{ fontSize: '14px' }} className="fw-bold">{t('Filter')}</Card.Title>
                                            <div className="tools">
                                                <Link to={"#"} onClick={(e) => { e.preventDefault(); }} className={`show ${openFilter ? 'collapse' : 'expand'}`}
                                                >
                                                    {openFilter ? <i className="fas fa-angle-up fs-18"></i> : <i className="fas fa-angle-down fs-18"></i>}
                                                </Link>
                                            </div>
                                        </Card.Header>
                                        <Collapse in={openFilter}>
                                            <Card.Body className="p-2 mb-2 mb-lg-3 mt-0 px-3 px-md-3 pt-0">
                                                <Col sm={12} lg={12} className="text-end align-self-center mt-0">
                                                    <div className="d-flex flex-column flex-md-row justify-content-start justify-content-lg-end align-items-end">
                                                        <div className="row flex-grow-1">
                                                            <div className="col-xl-3 col-lg-3 col-sm-12 mb-md-0 mb-2 mb-ld-3 text-start">
                                                                <label className='form-label mb-1'>{t('Interval')}</label>
                                                                <div className="basic-form w-100 d-flex justify-content-space-between align-items-end gap-2 " style={{ minHeight: '2.5rem' }}>
                                                                    {(INTERVAL_TYPE || []).map((item, ind) => (
                                                                        <div className="d-flex align-items-center vertical-align-middle p-0 ps-1" key={ind} onClick={() => {
                                                                            setFrequencyTypeSearch((prev: any) => item);
                                                                            handleChangePickerDefault(item?.value);
                                                                        }}>
                                                                            <RadioPrimary text={item.label} checked={frequencyTypeSearch?.value === item.value} />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-xl-4 col-sm-12 modal-date text-start">
                                                                <label className='form-label mb-1'>{t('Date')}</label>
                                                                <div className="input-hasicon mb-xl-0 mb-3">
                                                                    <RangeDatePickerNormal
                                                                        frequencyTypeSearch={frequencyTypeSearch}
                                                                        dateSearch={dateSearch}
                                                                        handleChangeDatePicker={handleChangePicker}
                                                                    />
                                                                    <div className="icon"><i className="far fa-calendar" /></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5 col-xl-5 col-sm-12 modal-date align-self-end text-start">
                                                                <ButtonPrimary
                                                                    text={t('Filter')}
                                                                    isLoading={isLoading}
                                                                    onClick={handleQueryData}
                                                                    className="btn-search-category"
                                                                    disabled={isLoading}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Card.Body>
                                        </Collapse>
                                    </Card>
                                </Col>
                                <Col sm="12">
                                    <Card className="mb-0 mb-md-4 rounded-2" style={{ border: 'var(--bs-border-width) solid var(--border)', boxShadow: 'none' }}>
                                        <Card.Header className="p-2 p-lg-3" style={{ borderBottom: '0px' }}>
                                            <Card.Title style={{ fontSize: '.875rem' }} className="fw-bold">{t('Trend Index Chart')}</Card.Title>
                                        </Card.Header>
                                        <Card.Body className="p-2 p-lg-3 pt-lg-0 mb-0">
                                            <RankingChart
                                                data={data?.ranking_changes_chart}
                                                name={data?.keyword?.keyword || ''}
                                                height={window.innerWidth < 750 ? 250 : 300}
                                                style={{ transform: 'translateY(0px)' }}
                                                isShowXaxis={true}
                                                isShowYaxis={true}
                                                isShowTooltip={true}
                                                showAxisBorder={true}
                                                fillColor="#FDEBC7"
                                                fillOpacity={0}
                                                markersSize={3}
                                                frequencyType={frequencyType}
                                                isRotate={window.innerWidth < 750}
                                                tickAmountXaxis={String(frequencyType) === String(FREQUENCY_TYPE.DAILY) ? 10 : 6}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm="12" md="5" lg="5" className="mb-3 mb-sm-4 mb-md-0">
                                    <Card className="mb-1 rounded-2" style={{ height: '100%', border: 'var(--bs-border-width) solid var(--border)', boxShadow: 'none' }}>
                                        <Card.Header className="p-2 p-lg-3" style={{ borderBottom: '0px' }}>
                                            <Card.Title style={{ fontSize: '14px' }} className="fw-bold">{t('Gender')}</Card.Title>
                                        </Card.Header>
                                        <Card.Body className="p-2 p-lg-3 pt-lg-2 mb-1 mt-0 blur-container">
                                            {!user?.user?.user_subscription?.subscription?.user_stats_flg && <div className="blur-content">
                                                <Link to="/subscription-introduction" className="text-center">
                                                    {t('This data will be available after changing your subscription plan!')}
                                                    <br />
                                                    <button className="text-center btn btn-secondary btn-xs px-3 py-1 fs-14 mt-1" style={{ minWidth: '90px' }}>
                                                        {t('Subscription')}
                                                    </button>
                                                </Link>
                                            </div>}
                                            <Table bordered className={`mb-0 table-detail-keyword ${!!user?.user?.user_subscription?.subscription?.user_stats_flg ? '' : 'blur'}`}>
                                                <thead>
                                                    <tr>
                                                        <th className="p-2 text-center fs-13">{t('Gender_Male')}</th>
                                                        <th className="p-2 text-center fs-13">{t('Gender_FeMale')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="p-2 text-center fs-13">
                                                            {!!user?.user?.user_subscription?.subscription?.user_stats_flg ? calculatePercentageAge?.search_male_value : '-'}
                                                        </td>
                                                        <td className="p-2 text-center fs-13">
                                                            {!!user?.user?.user_subscription?.subscription?.user_stats_flg ? calculatePercentageAge?.search_female_value : '-'}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm="12" md="7" lg="7">
                                    <Card className="mb-1 rounded-2" style={{ height: '100%', border: 'var(--bs-border-width) solid var(--border)', boxShadow: 'none' }}>
                                        <Card.Header className="p-2 p-lg-3" style={{ borderBottom: '0px' }}>
                                            <Card.Title style={{ fontSize: '.875rem' }} className="fw-bold">{t('Age')}</Card.Title>
                                        </Card.Header>
                                        <Card.Body className="p-2 p-lg-3 pt-lg-2 mb-1 mt-0 blur-container">
                                            {!user?.user?.user_subscription?.subscription?.user_stats_flg && <div className="blur-content">
                                                <Link to="/subscription-introduction" className="text-center">
                                                    {t('This data will be available after changing your subscription plan!')}
                                                    <br />
                                                    <button className="text-center btn btn-secondary btn-xs px-3 py-1 fs-14 mt-1" style={{ minWidth: '90px' }}>
                                                        {t('Subscription')}
                                                    </button>
                                                </Link>
                                            </div>}
                                            <Table bordered className={`mb-0 table-detail-keyword ${!!user?.user?.user_subscription?.subscription?.user_stats_flg ? '' : 'blur'}`}>
                                                <thead>
                                                    <tr>
                                                        <th className="p-2 text-center fs-13">{t('Age_10')}</th>
                                                        <th className="p-2 text-center fs-13">{t('Age_20')}</th>
                                                        <th className="p-2 text-center fs-13">{t('Age_30')}</th>
                                                        <th className="p-2 text-center fs-13">{t('Age_40')}</th>
                                                        <th className="p-2 text-center fs-13">{t('Age_50')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="p-2 text-center fs-13">
                                                            {!!user?.user?.user_subscription?.subscription?.user_stats_flg ? calculatePercentageGender?.search_age_10_value : '-'}
                                                        </td>
                                                        <td className="p-2 text-center fs-13">
                                                            {!!user?.user?.user_subscription?.subscription?.user_stats_flg ? calculatePercentageGender?.search_age_20_value : '-'}
                                                        </td>
                                                        <td className="p-2 text-center fs-13">
                                                            {!!user?.user?.user_subscription?.subscription?.user_stats_flg ? calculatePercentageGender?.search_age_30_value : '-'}
                                                        </td>
                                                        <td className="p-2 text-center fs-13">
                                                            {!!user?.user?.user_subscription?.subscription?.user_stats_flg ? calculatePercentageGender?.search_age_40_value : '-'}
                                                        </td>
                                                        <td className="p-2 text-center fs-13">
                                                            {!!user?.user?.user_subscription?.subscription?.user_stats_flg ? calculatePercentageGender?.search_age_50_value : '-'}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default React.memo(ModalKeywordDetail);