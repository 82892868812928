
import { KEYWORD_SOURCE_TYPE } from "helpers/constans";
import React from "react";
import Actor from "./Actor";
import BallPlayer from "./BallPlayer";
import GolfPlayer from "./GolfPlayer";
import Singer from "./Singer";
import SoccerPlayer from "./SoccerPlayer";
import Youtuber from "./Youtuber";
import Game from "./Game";
import BoardCaster from "./BoardCaster";
import Instagram from "./Instagram";
import NaverBlog from "./NaverBlog";

const InfoByType = ({ info = null, source_type = 0, onSeeMore }: any) => {

    const handleSeeMore = () => {
        onSeeMore && onSeeMore();
    }

    const renderContent = (keyword_info: any) => {
        switch (source_type) {
            case KEYWORD_SOURCE_TYPE.ACTOR:
                return <Actor keyword_info={keyword_info} handleSeeMore={handleSeeMore} />;
            case KEYWORD_SOURCE_TYPE.BASE_BALL_PLAYER:
                return <BallPlayer keyword_info={keyword_info} handleSeeMore={handleSeeMore} />;
            case KEYWORD_SOURCE_TYPE.GOLF_PLAYER:
                return <GolfPlayer keyword_info={keyword_info} handleSeeMore={handleSeeMore} />;
            case KEYWORD_SOURCE_TYPE.SINGER:
                return <Singer keyword_info={keyword_info} handleSeeMore={handleSeeMore} />;
            case KEYWORD_SOURCE_TYPE.SOCCER_PLAYER:
                return <SoccerPlayer keyword_info={keyword_info} handleSeeMore={handleSeeMore} />;
            case KEYWORD_SOURCE_TYPE.YOUTUBER:
                return <Youtuber keyword_info={keyword_info} handleSeeMore={handleSeeMore} />;
            case KEYWORD_SOURCE_TYPE.GAME:
                return <Game keyword_info={keyword_info} handleSeeMore={handleSeeMore} />;
            case KEYWORD_SOURCE_TYPE.BOARD_CASTER:
                return <BoardCaster keyword_info={keyword_info} handleSeeMore={handleSeeMore} />;
            case KEYWORD_SOURCE_TYPE.INSTAGRAM:
                return <Instagram keyword_info={keyword_info} handleSeeMore={handleSeeMore} />;
            case KEYWORD_SOURCE_TYPE.NAVER_BLOG:
                return <NaverBlog keyword_info={keyword_info} handleSeeMore={handleSeeMore} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <div>{renderContent(info)}</div>
        </>
    )
}

export default React.memo(InfoByType);