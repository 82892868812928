import React from "react";
import Tooltip from '@mui/material/Tooltip';
import InfoByType from "./InfoByType";
import { useMediaQuery } from '@mui/material';

const TooltipInfo = ({
    keyword = null,
    onSeeMore,
    children,
}: any) => {
    const isLargeScreen = useMediaQuery('(min-width:1000px)');
    const keyword_info: any = { ...keyword?.keyword_info, thumbnail: keyword?.image_url || '' };
    const keyword_type = keyword?.keyword_type;
    const handleSeeMore = () => {
        onSeeMore && onSeeMore();
    };

    return (
        <React.Fragment>
            {isLargeScreen ? (
                <Tooltip
                    title={<InfoByType info={keyword_info} source_type={keyword_type} />}
                    placement="left-start"
                    slotProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: '#f8f9fb',
                                padding: '4px 4px',
                                maxWidth: '100%',
                                boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
                            },
                        },
                    }}
                >
                    {children}
                </Tooltip>
            ) : (
                children
            )}
        </React.Fragment>
    );
};

export default React.memo(TooltipInfo);
