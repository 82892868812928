import React from "react";
import { useTranslation } from "react-i18next";
import NoImage from '../../../../../../assets/images/no-photo-available.png';

const BallPlayer = ({ keyword_info = {}, handleSeeMore }: any) => {
    const { t } = useTranslation();
    const onSeeMore = () => {
        handleSeeMore && handleSeeMore();
    }
    return (
        <>
            <div className="d-flex gap-2 p-2">
                <>
                    <div style={{ width: '150px', height: '150px', borderRadius: '8px', overflow: 'hidden', border: '1px solid #e6e6e6' }}>
                        <img alt={keyword_info?.name || ''} loading="lazy" src={keyword_info?.thumbnail || keyword_info?.image_url || keyword_info?.image || NoImage}
                            style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                                objectPosition: 'center',
                                backgroundColor: '#fff'
                            }}
                        />
                    </div>
                    <div>
                        <div>
                            <h3 className="mb-0">
                                {keyword_info?.name || ''}
                            </h3>
                        </div>
                        <table className="table table mb-0">
                            <tbody>
                                <tr className="border-0">
                                    <td className="border-0 fs-12 text-primary p-2 bg-secondary-100" width="70px">{t('Team')}</td>
                                    <td className="border-0 fs-12 p-2 text-muted text-start bg-secondary-100" width="130px">{keyword_info?.team || '-'}</td>
                                </tr>
                                <tr className="border-0">
                                    <td className="border-0 fs-12 text-primary p-2 bg-secondary-100" width="70px">{t('Birthday')}</td>
                                    <td className="border-0 fs-12 p-2 text-muted text-start bg-secondary-100" width="130px">{keyword_info?.birthday || '-'}</td>
                                </tr>
                                <tr className="border-0">
                                    <td className="border-0 fs-12 text-primary p-2 bg-secondary-100" width="70px">{t('Position')}</td>
                                    <td className="border-0 fs-12 p-2 text-muted text-start bg-secondary-100" width="130px">{keyword_info?.position || '-'}</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className="text-primary text-end cursor-pointer" onClick={() => onSeeMore()}> {t('See more')}</div> */}
                    </div>
                </>
            </div>
        </>
    )
}

export default React.memo(BallPlayer);